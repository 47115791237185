import ApiHelper from './index';

const fetchAllFeatures = async (spinner) => {
  return await ApiHelper.get(`feature_control/feature/`, spinner);
};

const createFeature = async (payload, spinner = true) => {
  return await ApiHelper.post(`feature_control/feature/`, payload, spinner);
};

const suppressFeature = async (id, params = {}, spinner = true) => {
  return await ApiHelper.remove(
    `feature_control/feature/${id}`,
    spinner,
    params
  );
};

const fetchAllFeatureSubscriptions = async (spinner = true) => {
  return await ApiHelper.get(`feature_control/subscribed_feature/`, spinner);
};

const createFeatureSubscription = async (payload, spinner = true) => {
  return await ApiHelper.post(
    `feature_control/subscribed_feature/`,
    payload,
    spinner
  );
};

const deleteSubscriptionFeature = async (id, params = {}, spinner = true) => {
  return await ApiHelper.remove(
    `feature_control/subscribed_feature/${id}`,
    spinner,
    params
  );
};

export default {
  fetchAllFeatures,
  createFeature,
  suppressFeature,
  fetchAllFeatureSubscriptions,
  createFeatureSubscription,
  deleteSubscriptionFeature,
};
