import { eventTypes } from 'src/components/admin-panel/pages/EventNotification/config';
import { actions } from 'src/config/long-running-task-config.js';
import { objectAnnotationModes } from 'src/config/task-steps-objects.js';
import trainingStatuses from 'src/config/training-status-config.js';
import EvenNotificationService from 'src/services/eventNotification';
import S3Service from 'src/services/s3';
import types from 'src/store/mutation-types';
import { useToast } from 'vue-toastification';
import {
  modes,
  taskUpdateJsonPhaseConfig,
} from '../config/task-edit-modes-config';

const toast = useToast();

export const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

export const isObject = (obj) =>
  obj !== undefined && obj !== null && obj.constructor == Object;

export const getDestinationIndex = (index, direction) => {
  if (direction === 'up') return index - 1;
  else return index + 1;
};

export const getTaskNameAndProcess = (taskDetail) => {
  if (!taskDetail) return;

  const task = {
    name: '',
    processes: [],
  };

  const text_lines = taskDetail?.split('\n');

  if (text_lines.length === 0) {
    return;
  }

  task.name = text_lines[0].split('=')[1].trim();
  let substeps = [];
  let steps = [];
  let step = '';
  for (let line of text_lines) {
    line = line.trim();
    if (line.at(-1) === ':' && substeps.length === 0) {
      step = line.slice(0, -1);
    } else if (line.at(-1) === ';') {
      substeps.push(line.slice(0, -1));
    } else if (line.at(-1) === ':' && substeps.length !== 0) {
      steps.push({
        name: step,
        substeps,
      });
      substeps = [];
      step = line.slice(0, -1);
    } else if (line.at(-1) === '~') {
      steps.push({
        name: step,
        substeps,
      });
      task.processes.push({
        name: line.slice(0, -1),
        steps,
      });
      steps = [];
      substeps = [];
      step = '';
    }
  }
  steps.push({
    name: step,
    substeps,
  });

  if (!task.processes.length)
    task.processes.push({
      name: ' Default',
      steps,
    });

  return task;
};

export const getSubstepList = (taskDetail) => {
  if (!taskDetail) return;

  const text_lines = taskDetail?.split('\n');

  if (text_lines.length === 0) {
    return;
  }

  let substeps = [];

  for (let line of text_lines) {
    line = line.trim();
    if (line.at(-1) === ';') substeps.push(line.slice(0, -1));
  }

  return substeps;
};

export const validateForSpecialCharacters = (state) => {
  const { newTask } = state;
  let errorMessage =
    "cannot contain special characters [$&+,:;=?@#|'<>.^*()%!]";
  if (newTask.match(/[^A-Za-z0-9\s_-]+/)) {
    toast.info(`Task with the name "${newTask}" ${errorMessage}`);
    return true;
  }
  return false;
};

export const checkIfTaskExist = (state) => {
  const { newTask, allTasks } = state;
  const flag = !allTasks.every((el) => el.taskName !== newTask);
  if (flag) {
    toast.info(`Task with the name "${newTask}" already exists!`);
    return true;
  }
  return false;
};

export const checkIfEmpty = (processes) => {
  const flag = !processes.every((process) => {
    const { name, steps } = process;
    return (
      name &&
      steps.every((step) => {
        const { name, substeps } = step;
        return name && substeps.every((substep) => substep);
      })
    );
  });
  if (flag) {
    toast.info('Please fill the empty steps!');
    return true;
  }
  return false;
};

export const checkIfSubstepsExists = (processes) => {
  const flag = !processes.every(
    (process) =>
      process.name &&
      process.steps.every((step) => step.name && step.substeps[0])
  );
  if (flag) {
    toast.info('Each Step must have atleast one sub-step!', {
      timeout: 3000,
    });
    return true;
  }
  return false;
};
export const validateStepAndSubstepCharacters = (processes) => {
  const invalidChars = /[`~;:]/; // Match any of the invalid characters

  for (const process of processes) {
    for (const step of process.steps) {
      if (step.name && invalidChars.test(step.name)) {
        toast.info(
          `Step name "${step.name}" contains invalid characters (~;:).`,
          {
            timeout: 3000,
          }
        );
        return true;
      }

      // Check substeps as well
      if (step.substeps && Array.isArray(step.substeps)) {
        for (const substep of step.substeps) {
          if (substep && invalidChars.test(substep)) {
            toast.info(
              `Substep name "${substep}" contains invalid characters (~;:).`,
              {
                timeout: 3000,
              }
            );
            return true;
          }
        }
      }
    }
  }
  return false;
};
export const isDuplicateProcess = (processes) => {
  const tempProcesses = processes.map((p) => p.name);
  if (tempProcesses.length !== new Set(tempProcesses).size) {
    toast.info('Processes name cannot be same!');
    return true;
  }
  return false;
};

export const isDuplicateStep = (processes) => {
  const steps = processes
    .reduce((acc, el) => [...acc, ...el.steps], [])
    .map((step) => step.name.trim());
  if (steps.length !== new Set(steps).size) {
    toast.info('Steps name cannot be same!');
    return true;
  }
  return false;
};

export const isDuplicateSubstep = (processes) => {
  const subStepsSet = new Set();
  for (let process of processes) {
    for (let step of process.steps) {
      for (let substep of step.substeps) {
        const step = ('' + substep).split(' ').join('');
        if (step !== '' && step !== ' ' && subStepsSet.has(step)) {
          toast.info('Substeps cannot be same!');
          return true;
        }
        subStepsSet.add(step);
      }
    }
  }

  return false;
};

export const isStepAndSubstepSame = (processes) => {
  var flag = false;
  processes.forEach((process) => {
    const processData = JSON.parse(JSON.stringify(process.steps));
    processData.forEach((element) => {
      const step = element.name.toString();
      const substeps = element.substeps;
      if (substeps.includes(step)) {
        toast.info('Step and Substep name cannot be same!');
        flag = true;
        return;
      }
    });
    if (flag) {
      return;
    }
  });
  return flag;
};

export const hasNoProcess = (processes) => {
  if (processes.length === 0) {
    toast.info('An operation must contain atleast one process!');
    return true;
  }
  return false;
};

export const hasNoSteps = (processes) => {
  const flag = processes.some((process) => process.steps.length === 0);
  if (flag) {
    toast.info('A process must contain atleast one step!');
    return true;
  }
  return false;
};

export const hasMorethanTwoProcess = (processes) => {
  if (processes.length > 2) {
    toast.info('An operation can contain at most 2 process!');
    return true;
  }
  return false;
};

export const getTaskString = (processes, taskName) => {
  let taskString = 'name=' + taskName.trim();

  processes.forEach((process) => {
    const { name: processName, steps } = process;
    steps.forEach((step) => {
      const { name: stepName, substeps } = step;
      taskString += '\n' + stepName + ':';
      substeps.forEach((substep) => {
        taskString += '\n' + substep + ';';
      });
    });
    taskString += '\n' + processName + '~';
  });
  return taskString;
};

export const startTaskModification = (state, commit) => {
  const { updatedJsonPayload, mergeStepsDict } = state;
  const jsonForMergePhase = getMergeStepJson(mergeStepsDict);
  const tempUpdatedJson = [
    ...updatedJsonPayload,
    JSON.parse(JSON.stringify(jsonForMergePhase)),
  ];
  commit(types.SET_UPDATED_JSON_PAYLOAD, tempUpdatedJson);
  if (!state.isUpdateTaskFromAdmin) {
    commit(types.SET_TASK_UPDATE_CONFIRM_MODAL, true);
  }
};

export const getStepsCount = (state) => {
  let count = 0;
  state.processes.forEach((process) => {
    process.steps.forEach((step) => {
      count += step.substeps.length;
    });
  });
  return count;
};

export const isTaskBeingTrained = ({ train_status }, taskName) => {
  if (train_status === trainingStatuses.training) {
    toast.info(
      `${taskName} is being trained, You cannot update the task when it is being trained.`
    );
    return true;
  }
  return false;
};

export const isTaskBeingPerformed = (devices, taskName) => {
  const isInferenceRunning = devices.length > 0;
  if (isInferenceRunning) {
    toast.info(
      `${taskName} is being performed, You cannot update the task when it is being performed.`
    );
    return true;
  }
  return false;
};

const getOldTaskNameAndStepDetails = (oldTaskDetails) => {
  let oldStepsDetail = oldTaskDetails.split('\n');
  const oldTaskName = oldStepsDetail.shift();
  oldStepsDetail.pop();
  oldStepsDetail = oldStepsDetail.join('\n');
  return { oldTaskName, oldStepsDetail };
};

const AreStepsNameRenamed = (updatedJsonPayload) => {
  const addDeleteStepsKeys = Object.keys(
    updatedJsonPayload[0]?.stepsJson
  )?.join(',');
  const renamedStepsKeys = Object.keys(updatedJsonPayload[1]?.stepsJson)?.join(
    ','
  );
  if (addDeleteStepsKeys !== renamedStepsKeys) return true;
  return false;
};

const getStepDataDefaultFormat = () => {
  const step_data_default_format = {
    objects_present: [],
    detect_always: true,
    boolean_expr: '',
    rule_types: {},
    step_relations: {
      'anchor-relation': {
        key_steps: [],
        before_anchor: [],
        after_anchor: [],
      },
      'similar-relation': {
        key_steps: [],
        before_anchor: [],
        after_anchor: [],
      },
    },
  };
  return step_data_default_format;
};

const getRenamedSubsteps = (updatedJsonPayload) => {
  const addDeleteJson = updatedJsonPayload[0]?.stepsJson;
  const renameJson = updatedJsonPayload[1]?.stepsJson;

  const addDeleteJsonSubsteps = Object.values(addDeleteJson).reduce(
    (res, el) => {
      const substeps = Object.keys(el);
      return [...res, substeps];
    },
    []
  );
  const renamedJsonSubsteps = Object.values(renameJson).reduce((res, el) => {
    const substeps = Object.keys(el);
    return [...res, substeps];
  }, []);

  let renamedStepsDict = {};
  addDeleteJsonSubsteps.forEach((substeps, index) => {
    const renamedsteps = renamedJsonSubsteps[index];
    substeps.forEach((e, i) => {
      if (e !== renamedsteps[i]) {
        renamedStepsDict[renamedsteps[i]] = e;
      }
    });
  });
  return renamedStepsDict;
};

const getListToUpdateMetaFile = (state, updatedJsonPayload) => {
  const { taskStepsCount } = state;
  const mergeJson = updatedJsonPayload[2]?.stepsJson;
  const isSubstepsRenamed =
    Object.keys(getRenamedSubsteps(updatedJsonPayload)).length !== 0;
  const dict = {
    [modes['addDelete']]: taskStepsCount !== getStepsCount(state),
    [modes['rename']]:
      AreStepsNameRenamed(updatedJsonPayload) || isSubstepsRenamed,
    [modes['merge']]: Object.keys(mergeJson).length !== 0,
  };
  const newUpdateJsonList = updatedJsonPayload
    .filter(({ editMode }) => dict[editMode] === true)
    .map(({ stepsJson, editMode }) => ({
      [taskUpdateJsonPhaseConfig[editMode]]: true,
      updated_json: stepsJson,
    }));
  return newUpdateJsonList;
};

const getTaskNameAndStepDetails = (taskString) => {
  const newStepDetails = taskString.split('\n');
  const taskName = newStepDetails?.shift()?.split('=')?.pop();
  newStepDetails.pop();
  return [taskName, newStepDetails.join('\n')];
};

const updatedTaskDetailsWhenMerge = (mergeStepsDict, taskDetails) => {
  const taskDetailsArray = taskDetails.split('\n');
  const mergeSteps = Object.values(mergeStepsDict)
    ?.reduce((res, el) => {
      res = [...res, ...el];
      return res;
    }, [])
    ?.map((step) => step + ';');

  if (!mergeSteps.length) return taskDetails;
  const filterDetails = taskDetailsArray.filter(
    (step) => !mergeSteps.some((mstep) => mstep === step)
  );
  return {
    updatedTaskString: filterDetails.join('\n'),
    mergeStepsCount: mergeSteps.length,
  };
};

export const setSubStepTimesJsonFile = (state, initSubStepTimeJson) => {
  const { taskDetails } = state;
  if (!Object.keys(initSubStepTimeJson)?.length) return initSubStepTimeJson;

  const { name, processes } = getTaskNameAndProcess(taskDetails);
  const indexToStepMappig = getIndexToStepMapping(processes);
  // if (Object.keys(initSubStepTimeJson)?.every((el) => Number.isNaN(Number(el)))) return initSubStepTimeJson

  let res = isObject(initSubStepTimeJson)
    ? deepClone(initSubStepTimeJson)
    : JSON.parse(initSubStepTimeJson);

  res = Object.entries(res).reduce((res, el) => {
    const step = indexToStepMappig[el[0]]
      ? indexToStepMappig[el[0]]
      : 'background';
    res[step] = el[1];
    return res;
  }, {});

  if (!res.hasOwnProperty('background')) {
    const steps = Object.keys(indexToStepMappig).length;
    res['background'] = res[steps.length] ? res[steps.length] : 50;
  }
  return res;
};

const updateSubstepTimesJsonOnEdit = (state, updatedJsonPayload) => {
  const { subStepTimesJson, processes } = state;
  if (!subStepTimesJson || !Object.keys(subStepTimesJson).length) return null;

  const newStepsToIndexMap = getStepToIndexMapping(processes);
  const mergeStepsJson = updatedJsonPayload[2]?.stepsJson;
  const renameStepsObj = getRenamedSubsteps(updatedJsonPayload);
  const mergeKeys = Object.keys(mergeStepsJson);
  const mergedSteps = Object.values(mergeStepsJson)?.reduce(
    (res, el) => [...res, ...el],
    []
  );

  const updatedSubstepTime = Object.entries(subStepTimesJson).reduce(
    (res, el) => {
      res[el[0]] = Number(el[1]);
      return res;
    },
    {}
  );

  let json = {};
  const newSteps = Object.keys(newStepsToIndexMap);
  newSteps.forEach((step) => {
    const stepIndex = Object.keys(json).length;
    if (subStepTimesJson.hasOwnProperty(step)) {
      if (mergeKeys.length && mergeKeys?.includes(step)) {
        const factor = updatedSubstepTime[step];
        const currMergedSteps = mergeStepsJson[step];
        const mergeStepTimeSum = currMergedSteps.reduce((res, el) => {
          return (res += Number(updatedSubstepTime[el]));
        }, factor);
        json[stepIndex] = mergeStepTimeSum / (currMergedSteps.length + 1);
      } else if (!mergedSteps?.includes(step)) {
        json[stepIndex] = updatedSubstepTime[step];
      }
    } else if (Object.keys(renameStepsObj).includes(step)) {
      json[stepIndex] = updatedSubstepTime[renameStepsObj[step]]
        ? updatedSubstepTime[renameStepsObj[step]]
        : 1;
    } else {
      json[stepIndex] = 1;
    }
  });
  const lastStep = Object.keys(json).length;
  json[lastStep] = updatedSubstepTime['background']
    ? updatedSubstepTime['background']
    : 50;
  return json;
};

const updateStepDataJsonOnEdit = (state, updatedJsonPayload) => {
  const { stepsJsonData, processes, indexToStepsMapping } = state;
  if (!stepsJsonData || !Object.keys(stepsJsonData).length) return null;

  const defaultStepFormat = getStepDataDefaultFormat();
  const newStepsToIndexMap = getStepToIndexMapping(processes);
  const mergeStepsJson = updatedJsonPayload[2]?.stepsJson;
  const renameStepsObj = getRenamedSubsteps(updatedJsonPayload);
  const mergeKeys = Object.keys(mergeStepsJson);
  const mergedSteps = Object.values(mergeStepsJson)?.reduce(
    (res, el) => [...res, ...el],
    []
  );

  const updatedStepDataJSON = Object.entries(stepsJsonData).reduce(
    (res, el) => {
      const stepName = indexToStepsMapping[el[0]];
      res[stepName] = el[1];
      return res;
    },
    {}
  );

  let newStepDataJSON = {};
  const newSteps = Object.keys(newStepsToIndexMap);
  newSteps.forEach((step) => {
    const stepIndex = Object.keys(newStepDataJSON).length;
    if (updatedStepDataJSON.hasOwnProperty(step)) {
      // if steps being merge in old step
      if (mergeKeys.length && mergeKeys?.includes(step)) {
        newStepDataJSON[stepIndex] = defaultStepFormat;
      } // if not merged & exist in old json
      else if (!mergedSteps?.includes(step)) {
        newStepDataJSON[stepIndex] = updatedStepDataJSON[step];
      }
    } //if step renamed
    else if (Object.keys(renameStepsObj).includes(step)) {
      const stepBeforeRename = renameStepsObj[step];
      newStepDataJSON[stepIndex] = updatedStepDataJSON[stepBeforeRename]
        ? updatedStepDataJSON[stepBeforeRename]
        : defaultStepFormat;
    } // if new step
    else {
      newStepDataJSON[stepIndex] = defaultStepFormat;
    }
  });
  console.log('step data json->', newStepDataJSON);
  return newStepDataJSON;
};

export const getTaskUpdatePayload = (state, oldTaskDetails) => {
  const {
    taskString,
    newTask,
    selectedTask,
    updatedJsonPayload,
    mergeStepsDict,
  } = state;
  const [oldTaskname, oldStepDetails] =
    getTaskNameAndStepDetails(oldTaskDetails);
  const [_, newStepDetails] = getTaskNameAndStepDetails(taskString);

  let taskPayload = {
    organization: state.taskOrganization,
    task_id: selectedTask,
    taskName: newTask,
    task_detail: taskString,
    stepsCount: getStepsCount(state),
    action: actions.updateTask,
    translation: null,
    translation_name: '',
    old_task_name: oldTaskname,
    is_task_updated: true,
  };

  taskPayload['is_update_meta_files'] =
    oldStepDetails !== newStepDetails ||
    Object.keys(mergeStepsDict).length !== 0;

  taskPayload['is_task_renamed'] = oldTaskname !== newTask;

  if (Object.keys(mergeStepsDict).length) {
    const { updatedTaskString, mergeStepsCount } = updatedTaskDetailsWhenMerge(
      mergeStepsDict,
      taskString
    );
    taskPayload['task_detail'] = updatedTaskString;
    taskPayload['stepsCount'] = taskPayload['stepsCount'] - mergeStepsCount;
  }

  const { is_update_meta_files } = taskPayload;
  if (is_update_meta_files)
    taskPayload['task_json_list'] = getListToUpdateMetaFile(
      state,
      updatedJsonPayload
    );

  taskPayload['sub_step_times'] = updateSubstepTimesJsonOnEdit(
    state,
    updatedJsonPayload
  );

  taskPayload['step_data_json'] = updateStepDataJsonOnEdit(
    state,
    updatedJsonPayload
  );

  const updatedStepsIds = getUpdatedStepsIndexes(
    state,
    taskPayload['task_detail']
  );

  taskPayload['deleted_steps_index'] = updatedStepsIds['deleted_steps'];
  taskPayload['modified_steps_index'] = updatedStepsIds['updated_steps'];
  taskPayload['new_steps_index'] = updatedStepsIds['new_steps'];
  const updateTaskParams = getUpdatedTaskParams(state, updatedStepsIds);
  taskPayload = { ...taskPayload, ...updateTaskParams };
  return taskPayload;
};

const getUpdatedRequiredSteps = (updatedSteps, required_steps) => {
  let updated_dict = {};
  const { deleted_steps, updated_steps } = updatedSteps;
  for (const [processIdx, steps] of Object.entries(required_steps)) {
    let newIndex = null;
    for (const s of steps) {
      const movedStep = updated_steps.find((st) => st[0] === s);
      if (movedStep) newIndex = movedStep[1];
      else if (!deleted_steps.includes(s)) newIndex = s;
      if (newIndex !== null && newIndex !== undefined) {
        updated_dict[processIdx]
          ? updated_dict[processIdx].push(newIndex)
          : (updated_dict[processIdx] = [newIndex]);
      }
      newIndex = null;
    }
  }
  return JSON.stringify(updated_dict);
};

function getUpdatedTaskParams(state, updatedSteps) {
  const {
    taskParameters: { telemetryParams },
  } = state;
  let { required_steps_for_end, required_steps_for_start } = telemetryParams;
  const obj = {
    required_steps_for_start: getUpdatedRequiredSteps(
      updatedSteps,
      required_steps_for_start
    ),
    required_steps_for_end: getUpdatedRequiredSteps(
      updatedSteps,
      required_steps_for_end
    ),
  };
  return obj;
}

export const initializeTranslation = (state) => {
  const { taskProcesses } = state;
  return taskProcesses.map((process) => {
    const tempProcess = {
      name: '',
      steps: process.steps.map((step) => {
        const tempStep = {
          name: '',
          substeps: step.substeps.map((_) => ''),
        };
        return tempStep;
      }),
    };
    return tempProcess;
  });
};

const getFileUploadPayload = (state, fileName, json) => {
  const { taskName } = state;
  var blob = new Blob([json], { type: 'text/json;charset=utf-8' });
  var payload = new FormData();
  const bucket = `${localStorage.getItem('organization')}-training`;
  const filePath = `${taskName}/${fileName}`;
  payload.append('file', blob, `${fileName}`);
  payload.append('bucket', bucket);
  payload.append('file_path', filePath);
  return payload;
};

const getFileDeletePayload = (state, fileName) => {
  const { taskName } = state;
  const organization = localStorage.getItem('organization');
  return {
    bucket: `${organization}-training`,
    file_path: `${taskName}/${fileName}`,
    organization,
  };
};

export const updateSimilarStepsFileS3 = (state, json) => {
  return new Promise(async (resolve, _) => {
    const fileName = 'similarSteps.json';
    if (json === '{}') {
      const payload = getFileDeletePayload(state, fileName);
      await S3Service.deleteFile(payload, false);
    } else {
      const payload = getFileUploadPayload(state, fileName, json);
      await S3Service.uploadFile(payload, false);
    }
    resolve();
  });
};

export const isTranslationNotExist = (translation) => {
  const flag =
    ['null', null, '{}'].includes(translation) ||
    Object(translation)?.keys?.length === 0;

  return flag;
};

export const getIndexToStepMapping = (processes) => {
  const process = {};

  processes.forEach((p) => {
    if (!process['steps']) process['steps'] = [...p.steps];
    else process['steps'] = [...process['steps'], ...p.steps];
  });

  return process?.steps
    ?.reduce((res, el) => [...res, ...el.substeps], [])
    .reduce((res, el, index) => {
      res[index] = el;
      return res;
    }, {});
};

export const getStepToIndexMapping = (processes) => {
  var substeps = {};
  processes.forEach((process) => {
    const prevSubStepsLength = Object.values(substeps).length;
    const processSubSteps = process.steps
      .reduce((res, el) => [...res, ...el.substeps], [])
      .reduce((res, el, index) => {
        res[el] = index + prevSubStepsLength;
        return res;
      }, {});
    substeps = { ...substeps, ...processSubSteps };
  });
  return substeps;
};

export const updateTaskObjectRules = (rules, payload) => {
  const prevRules = rules.filter((rule) => rule.id !== payload.id);
  return [...prevRules, payload];
};

export const convertStepTimeValuesToNumber = (stepTime) => {
  const { avgCycleTime, stepAvgTime } = stepTime;
  stepTime['avgCycleTime'] = Number(avgCycleTime);
  stepTime['stepAvgTime'] = Object.entries(stepAvgTime).reduce(
    (res, [key, value]) => {
      res[key] = Number(value);
      return res;
    },
    {}
  );
  return { ...stepTime };
};

export const getTaskUpdateJson = (processes, editMode) => {
  const temp = {};
  if (taskUpdateJsonPhaseConfig[editMode] === 'is_step_merged') return temp;
  processes.forEach((process) => {
    process.steps.forEach((step) => {
      const { name: stepName, substeps } = step;
      temp[stepName] = {
        [stepName]: {
          typeP: 4,
          step: stepName,
          labelText: stepName,
          secondMax: 0,
          isBeingLabled: false,
        },
      };

      substeps.forEach((substep) => {
        temp[stepName][substep] = {
          typeP: 3,
          step: stepName,
          labelText: substep,
        };
      });
    });
  });
  return { stepsJson: temp, editMode: editMode };
};

export const getPrevStepsJson = (processes) => {
  const temp = {};
  processes.forEach((process) => {
    process.steps.forEach((step) => {
      const { name: stepName, substeps } = step;
      temp[stepName] = true;

      substeps.forEach((substep) => {
        temp[substep] = true;
      });
    });
  });
  return temp;
};

export const getPayloadToUpdateCloneTaskBop = (task, parent_task) => {
  var taskPayload = {
    organization: task.Organization.Org_name,
    task_detail: parent_task.task_detail,
    stepsCount: parent_task.stepsCount,
    taskName: task.taskName,
    old_task_name: task.taskName,
    translation: null,
    translation_name: '',
    task_id: task.id,
    is_update_meta_files: true,
    action: actions.updateTask,
  };
  const processes = getTaskNameAndProcess(parent_task.task_detail)['processes'];
  const json = Object.values(modes).map((editMode) => ({
    [taskUpdateJsonPhaseConfig[editMode]]: true,
    updated_json: getTaskUpdateJson(processes, editMode).stepsJson,
  }));
  taskPayload['task_json_list'] = json;
  return taskPayload;
};

export const getUpdatedTasksList = (state) => {
  const { allTasks, selectedTask, newTask } = state;
  const index = allTasks.findIndex((t) => t.id === selectedTask);
  if (index >= 0) {
    allTasks[index]['taskName'] = newTask;
    return [...allTasks];
  } else return allTasks;
};

export const getSortedTask = (taskList) => {
  return [...taskList]
    .sort((a, b) => b.starred - a.starred)
    .map((el) => ({
      ...el,
      value: el.id,
      label: (el.starred ? '⭐ ' : '') + el.taskName,
    }));
};

export const updatedAttributeSteps = (stepsMapping, updateAttribute) => {
  return Object.values(stepsMapping)
    .filter((e) => e[updateAttribute])
    .map((e) => e.index)
    .sort()
    .join(',');
};

export const getMergeStepJson = (mergeStepsDict) => {
  const formattedDict = Object.entries(mergeStepsDict).reduce((res, el) => {
    const [key, steps] = el;
    const [mergingStep, _] = key.split('=');
    res[mergingStep] = [...steps];
    return res;
  }, {});
  return { stepsJson: formattedDict, editMode: modes.merge };
};

export const getSubstepToStepMapping = (processes) => {
  let substeps = {};
  let count = 0;
  processes.forEach((process) => {
    const processSubSteps = process.steps.reduce((res, el, stepIndex) => {
      const map = el.substeps.reduce((subRes, elm) => {
        subRes[elm] = count + stepIndex;
        return subRes;
      }, {});
      return { ...res, ...map };
    }, {});
    count = count + process.steps.length;
    substeps = { ...substeps, ...processSubSteps };
  });
  return substeps;
};

export const setOldTaskDetails = (taskDetails) => {
  const hasDefaultProcess = taskDetails.split('\n').pop().at(-1) === '~';
  let taskString = taskDetails;
  if (!hasDefaultProcess) {
    taskString += '\n' + 'Default~';
  }
  return taskString;
};

export const getProcessToStepsMap = (processes) => {
  processes = processes.map(
    (p) => p.steps.reduce((res, step) => [...res, ...step.substeps], []).length
  );
  let index = 0;
  let prev = 0;
  const res = processes.map((p) => {
    let arr = [];
    for (let i = index; i < p + prev; i++) {
      arr.push(i);
      index++;
    }
    prev += arr.length;
    return arr;
  });
  return Object.assign({}, res);
};

const getStepsList = (text) => {
  if (!text) {
    return;
  }
  const task = {
    name: '',
    steps: [],
  };
  const text_lines = text?.split('\n');

  task.name = text_lines[0].split('=')[1];
  let substeps = [];
  let step = '';
  for (let line of text_lines) {
    line = line.trim();

    if (line[line.length - 1] == ':' && substeps.length == 0) {
      step = line.slice(0, -1);
    } else if (line[line.length - 1] == ';') {
      substeps.push(line.slice(0, -1));
    } else if (line[line.length - 1] == ':' && substeps.length != 0) {
      task.steps.push({
        name: step,
        substeps,
      });
      substeps = [];
      step = line.slice(0, -1);
    }
  }
  task.steps.push({
    name: step,
    substeps,
  });
  return task;
};

export const getTranslatedSteps = (
  firstLangDetails,
  secondLangDetails = null
) => {
  const defaultLangSteps = getStepsList(firstLangDetails)?.steps;
  var translationLangSteps = null;
  if (secondLangDetails) {
    translationLangSteps = getStepsList(secondLangDetails).steps;
  }
  const obj = {};

  defaultLangSteps?.forEach((step, i) => {
    obj[step.name] = translationLangSteps
      ? translationLangSteps[i].name
      : step.name;
    step.substeps?.forEach((substep, j) => {
      var translatedSubStep = translationLangSteps
        ? translationLangSteps[i].substeps[j]
        : substep;
      obj[substep] = translatedSubStep;
    });
  });
  return obj;
};

export const getIdToObjectMapping = (objects) => {
  return objects.reduce((res, el) => {
    res[el.id] = el.name;
    return res;
  }, {});
};

export const getObjectToIdMapping = (objects) => {
  return objects.reduce((res, el) => {
    res[el.name] = el.id;
    return res;
  }, {});
};

export const initAnnotationObjectsJson = (state) => {
  let temp = {
    [objectAnnotationModes.annotation]: {},
    [objectAnnotationModes.verification]: {},
  };
  const {
    processToStepListMap,
    stepsJsonData: steps_json_data,
    taskObjectRules,
  } = state;
  const processSteps = Object.values(processToStepListMap);
  let verification_data = {};

  Object.keys(taskObjectRules).forEach((idx) => {
    const data = taskObjectRules[idx];
    const step = Number(data.step);
    const object_name = data.name;
    const static_object = data.static_object;
    const non_static_object = data.non_static_object;

    if (verification_data.hasOwnProperty(step)) {
      if (static_object) {
        verification_data[step].static_object = object_name;
      } else if (non_static_object) {
        verification_data[step].non_static_object = object_name;
      }
    } else {
      verification_data[step] = {
        static_object: static_object ? object_name : null,
        non_static_object: non_static_object ? object_name : null,
        verify: false,
      };
    }

    if (
      verification_data[step].static_object &&
      verification_data[step].non_static_object
    ) {
      verification_data[step]['verify'] = true;
    }
  });

  temp[objectAnnotationModes.verification] = verification_data;

  Object.keys(objectAnnotationModes).forEach((mode) => {
    processSteps.forEach((substeps) => {
      substeps.forEach((substepIndex) => {
        const substepIdx = substepIndex.toString();
        if (
          steps_json_data &&
          steps_json_data.hasOwnProperty(substepIdx) &&
          mode === objectAnnotationModes['annotation'] &&
          steps_json_data[substepIdx].hasOwnProperty('objects_present')
        ) {
          let static_obj = null;
          if (steps_json_data[substepIdx].hasOwnProperty('rule_type')) {
            const rule_type_name = Object.keys(
              steps_json_data[substepIdx].rule_type
            )[0];
            if (
              steps_json_data[substepIdx]['rule_type'][
                rule_type_name
              ].hasOwnProperty('region')
            ) {
              const region =
                steps_json_data[substepIdx]['rule_type'][rule_type_name].region;
              static_obj = region ? region : null;
            }
          }
          temp[mode][substepIndex] = {
            static_object: static_obj,
            non_static_object: steps_json_data[substepIndex]['objects_present'],
          };
        } else if (
          !(
            mode === objectAnnotationModes['verification'] &&
            temp[mode].hasOwnProperty(substepIndex)
          )
        ) {
          const stepObj = {
            static_object: null,
            non_static_object:
              mode === objectAnnotationModes['annotation'] ? [] : null,
          };
          if (mode === objectAnnotationModes['verification']) {
            stepObj['verify'] = false;
          }

          temp[mode][substepIndex] = { ...stepObj };
        }
      });
    });
  });
  return temp;
};

export const getTaskParameters = (taskDetails) => {
  const {
    maximum_cycle_time,
    minimum_cycle_time,
    min_percentage_of_steps_required_to_end_cycle,
    verify_threshold,
    temporal_smoothness,
    object_rotation,
    add_background_time_to_step_time,
  } = taskDetails;
  let { training_params, cycle_calculation_params } = taskDetails.taskJson;

  let telemetry_params = {
    maximum_cycle_time: maximum_cycle_time,
    minimum_cycle_time: minimum_cycle_time,
    temporal_smoothness: temporal_smoothness,
    object_rotation: object_rotation,
    add_background_time_to_step_time: add_background_time_to_step_time,
  };

  if (Object.keys(cycle_calculation_params).length) {
    telemetry_params = {
      ...telemetry_params,
      ...cycle_calculation_params,
      min_percentage_of_steps_required_to_end_cycle: cycle_calculation_params[
        'min_percentage_of_steps_required_to_end_cycle'
      ]
        ? cycle_calculation_params[
            'min_percentage_of_steps_required_to_end_cycle'
          ]
        : 0.75,
    };
  } else {
    // TODO: remove else condition, will never execute now.
    telemetry_params = {
      ...telemetry_params,
      min_percentage_of_steps_required_to_end_cycle: null,
      required_steps_for_end: { 0: [] },
      required_steps_for_start: { 0: [0, 1] },
    };
  }

  training_params = {
    ...training_params,
    verify_threshold: verify_threshold ? verify_threshold : 6,
  };
  return {
    telemetryParams: deepClone(telemetry_params),
    trainingParams: deepClone(training_params),
  };
};

const getStepsData = (stepsToIndexMapping, taskDetails) => {
  const processes = getTaskNameAndProcess(taskDetails).processes;
  const newStepsToIndexMapping = getStepToIndexMapping(processes);
  const obj = {
    oldStepsMap: stepsToIndexMapping,
    newStepsMap: newStepsToIndexMapping,
    oldSteps: Object.keys(stepsToIndexMapping),
    newSteps: Object.keys(newStepsToIndexMapping),
  };
  return obj;
};

export const getUpdatedStepsIndexes = (state, taskDetails) => {
  const { stepsToIndexMapping, stepsAssociationIds, updatedJsonPayload } =
    state;
  const { oldStepsMap, oldSteps, newStepsMap, newSteps } = getStepsData(
    stepsToIndexMapping,
    taskDetails
  );
  const renamedStepsDict = getRenamedSubsteps(updatedJsonPayload);
  const renamedSteps = Object.values(renamedStepsDict);
  const newRenamedSteps = Object.keys(renamedStepsDict);

  let removeStepIndexes = oldSteps
    .filter((step) => !newSteps.includes(step) && !renamedSteps.includes(step))
    .map((s) => oldStepsMap[s]);

  let modifiedStepDict = {};
  let newStepsIndexes = [];

  newSteps.forEach((step, newStepIndex) => {
    const oldStepIndex = oldStepsMap[step];
    // if new step or renamed
    if (!oldSteps.includes(step)) {
      // add newly added step
      if (newRenamedSteps.includes(step)) {
        const oldstep = renamedStepsDict[step];
        modifiedStepDict[step] = [oldStepsMap[oldstep], newStepsMap[step]];
      } else newStepsIndexes.push(newStepsMap[step]);

      // updated indexes after adding new step
      const changedSteps = newSteps.slice(newStepIndex + 1, newSteps.length);
      changedSteps.forEach((sp) => {
        if (oldSteps.includes(sp)) {
          modifiedStepDict[sp] = [oldStepsMap[sp], newStepsMap[sp]];
        } else if (newRenamedSteps.includes(sp)) {
          const oldstep = renamedStepsDict[sp];
          modifiedStepDict[sp] = [oldStepsMap[oldstep], newStepsMap[sp]];
        }
      });
    } // else if step moved
    else if (!isSameIndex(oldStepIndex, newStepIndex)) {
      modifiedStepDict[step] = [oldStepsMap[step], newStepsMap[step]];
    }
  });
  let updateStepsIndexes = [];
  Object.values(modifiedStepDict).forEach(([source, destination]) => {
    updateStepsIndexes.push([source, destination]);
  });

  const obj = {
    deleted_steps: removeStepIndexes,
    updated_steps: updateStepsIndexes,
    new_steps: newStepsIndexes,
  };
  return obj;
};

const isSameIndex = (oldIndex, newIndex) => {
  if (oldIndex === undefined) return 1;
  return oldIndex === newIndex;
};

export const getUpdatedTaskJson = (state) => {
  const { taskJson, taskParameters } = state;
  const { telemetryParams, trainingParams } = taskParameters;
  const { maximum_cycle_time, minimum_cycle_time, ...resTelemetryParams } =
    telemetryParams;
  return {
    ...taskJson,
    cycle_calculation_params: { ...resTelemetryParams },
    training_params: { ...trainingParams },
  };
};

const getEmailBody = (
  organization,
  taskId,
  taskName,
  removedSteps,
  addedSteps
) => {
  const _removedSteps = removedSteps
    .map(
      (el) =>
        `<tr style="border: 1px solid #dddddd;"><td style="border: 1px solid #dddddd; padding: 8px;">${el.stepIndex}</td><td style="border: 1px solid #dddddd; padding: 8px; color:red;">${el.stepName}</td></tr>`
    )
    .join('');
  const _addedSteps = addedSteps
    .map(
      (el) =>
        `<tr style="border: 1px solid #dddddd;"><td style="border: 1px solid #dddddd; padding: 8px;">${el.stepIndex}</td><td style="border: 1px solid #dddddd; padding: 8px; color:green;">${el.stepName}</td></tr>`
    )
    .join('');

  return `<body style="font-family: Arial, sans-serif; margin: 0; padding: 20px;"><div class="task-details" style="margin-bottom: 30px;"><p style="margin: 5px 0;"><strong>Organization:</strong> ${organization}</p><p style="margin: 5px 0;"><strong>Task ID:</strong> ${taskId}</p><p style="margin: 5px 0;"><strong>Task Name:</strong> ${taskName}</p></div><div class="removed-steps" style="margin-top: 10px;"><h3 style="margin-bottom: 5px;">Removed Steps:</h3><table style="border-collapse: collapse; width: 100%; margin-bottom: 10px;"><thead><tr><th style="border: 1px solid #dddddd; background-color: #f2f2f2; padding: 8px; text-align: left;">Step Index</th><th style="border: 1px solid #dddddd; background-color: #f2f2f2; padding: 8px; text-align: left;">Step Description</th></tr></thead><tbody>${_removedSteps}</tbody></table></div><div class="added-steps" style="margin-top: 10px;"><h3 style="margin-bottom: 5px;">Added Steps:</h3><table style="border-collapse: collapse; width: 100%; margin-bottom: 10px;"><thead><tr><th style="border: 1px solid #dddddd; background-color: #f2f2f2; padding: 8px; text-align: left;">Step Index</th><th style="border: 1px solid #dddddd; background-color: #f2f2f2; padding: 8px; text-align: left;">Step Description</th></tr></thead><tbody>${_addedSteps}</tbody></table></div></body>`;
};

const sendNotificationEmail = async (
  taskId,
  taskName,
  organization,
  recipients,
  removedSteps,
  addedSteps
) => {
  const payload = {
    recipients: recipients,
    subject: `Task Definition Updated: ${taskName}`,
    body: getEmailBody(
      organization,
      taskId,
      taskName,
      removedSteps,
      addedSteps
    ),
  };

  const [error] = await EvenNotificationService.sendEmail(payload);
  if (error) {
    console.log(error);
  }
};

export const sendTaskUpdateNotification = async (
  state,
  taskUpdatePayload,
  oldTaskDetails
) => {
  const { selectedTask, taskName } = state;
  const { deleted_steps_index, new_steps_index, task_detail } =
    taskUpdatePayload;
  const organization = localStorage.getItem('organization');
  const eventType = eventTypes['Task Update'];
  const [error, data] = await EvenNotificationService.getRecipients(
    organization,
    eventType
  );
  if (error) {
    console.log(error);
    return;
  }
  if (data.recipients.length) {
    const newSubstepsList = getSubstepList(task_detail);
    const oldSubstepsList = getSubstepList(oldTaskDetails);
    const removedSteps = deleted_steps_index.map((el) => ({
      stepIndex: el,
      stepName: oldSubstepsList[el],
    }));
    const addedSteps = new_steps_index.map((el) => ({
      stepIndex: el,
      stepName: newSubstepsList[el],
    }));
    sendNotificationEmail(
      selectedTask,
      taskName,
      organization,
      data.recipients,
      removedSteps,
      addedSteps
    );
  }
};
