import videoTypes from '../../config/video-type-config';
import VideosService from '../../services/videos';
import { getQueryParams } from '../../utils/videos';
import types from '../mutation-types';

const state = {
  videoList: [],
  isFetchingVideos: false,
  videosCount: 0,
  videoTypes,
  selectedVideoType: videoTypes['All Videos'],
  organizationTags: [],
};

const getters = {
  videoList: (state) => state.videoList,
  isFetchingVideos: (state) => state.isFetchingVideos,
  videosCount: (state) => state.videosCount,
  videoTypes: (state) => state.videoTypes,
  selectedVideoType: (state) => state.selectedVideoType,
  organizationTags: (state) => state.organizationTags,
  tagIdToNameMap: (state) =>
    state.organizationTags.reduce((res, el) => {
      res[el.id] = el.tag_name;
      return res;
    }, {}),
};

const actions = {
  async fetchOrganizationTags({ commit }) {
    const [error, data] = await VideosService.fetchOrganizationTags();
    if (error) {
      console.log({ error });
      return;
    }
    commit(types.SET_ORGANIZATION_TAGS, data);
  },

  addNewTag({ state, commit }, newTag) {
    commit(types.SET_ORGANIZATION_TAGS, [...state.organizationTags, newTag]);
  },

  removeTag({ state, commit }, tagId) {
    commit(
      types.SET_ORGANIZATION_TAGS,
      state.organizationTags.filter((el) => el.id !== tagId)
    );
  },

  async fetchVideos({ commit, dispatch }, { taskId, ...rest }) {
    commit(types.SET_IS_FETCHING_VIDEOS, true);
    const queryParams = getQueryParams(rest);
    const [error, data] = await VideosService.fetchVideos(
      taskId,
      false,
      queryParams
    );
    commit(types.SET_IS_FETCHING_VIDEOS, false);
    if (error) {
      console.log({ error });
      return;
    }
    const { results, count } = data;
    const video_list = results.map((v) => ({
      ...v,
      isThumbnailExist: true,
    }));
    commit(types.SET_VIDEOS_LIST, video_list);
    commit(types.SET_VIDEOS_COUNT, count);
  },

  updateVideoItem({ commit, state }, video) {
    const temp = [...state.videoList];
    const itemIndex = temp.findIndex((v) => v.id === video.id);
    temp[itemIndex] = { ...video };
    commit(types.SET_VIDEOS_LIST, temp);
  },

  setVideosList({ commit }, payload) {
    commit(types.SET_VIDEOS_LIST, payload);
  },
  setVideosCount({ commit }, payload) {
    commit(types.SET_VIDEOS_COUNT, payload);
  },

  setSelectedVideoType({ commit }, payload) {
    commit(types.SET_SELECTED_VIDEO_TYPE, payload);
  },
};

const mutations = {
  [types.SET_ORGANIZATION_TAGS](state, payload) {
    state.organizationTags = payload;
  },
  [types.SET_VIDEOS_LIST](state, payload) {
    state.videoList = payload;
  },
  [types.SET_VIDEOS_COUNT](state, payload) {
    state.videosCount = payload;
  },
  [types.SET_IS_FETCHING_VIDEOS](state, payload) {
    state.isFetchingVideos = payload;
  },
  [types.SET_SELECTED_VIDEO_TYPE](state, payload) {
    state.selectedVideoType = payload;
  },
  [types.SET_SELECTED_VIDEO_TYPE](state, payload) {
    state.selectedVideoType = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
