import types from '../mutation-types';
import RemoteDeviceService from 'src/services/remoteDevices';
import { useToast } from 'vue-toastification';

const toast = useToast();
const state = {
  remoteCamsList: [],
  isFetchingCamsList: false,
  showRemoteCamModal: false,
  remoteCamModalLoader: false,
  isEditRemoteDevice: null
};

const getters = {
  remoteCamsList: state => state.remoteCamsList,
  isFetchingCamsList: state => state.isFetchingCamsList,
  showRemoteCamModal: state => state.showRemoteCamModal,
  remoteCamModalLoader: state => state.remoteCamModalLoader,
  isEditRemoteDevice: state => state.isEditRemoteDevice
};

const actions = {
  updateRemoteCamModalState({ commit }, payload) {
    commit(types.SET_SHOW_REMOTE_CAM_MODAL, payload);
  },

  updateRemoteCamModalLoader({ commit }, payload) {
    commit(types.SET_REMOTE_CAM_MODAL_LOADER, payload);
  },

  async addNewRemoteCam({ commit, state }, payload) {
    const { remoteCamsList } = state;
    const [error, data] = await RemoteDeviceService.addNewRemoteDevice(
      true,
      payload
    );
    if (error) {
      toast.info("there's some error");
      return;
    }
    commit(types.SET_REMOTE_CAMS_LIST, [...remoteCamsList, data]);
  },

  async updateRemoteCamDevice({ state, commit }, payload) {
    const tempRemoteCamsList = [...state.remoteCamsList];
    const [error] = await RemoteDeviceService.updateRemoteCamDevice(payload);
    if (error) {
      toast.info("there's some error while assigning new device to remote cam");
      return;
    }
    toast.success('device updated for remote cam');

    const camDeviceIndex = tempRemoteCamsList.findIndex(
      camDevice => camDevice.id === payload.id
    );
    tempRemoteCamsList[camDeviceIndex] = payload;

    commit(types.SET_REMOTE_CAMS_LIST, tempRemoteCamsList);
  },

  async deleteRemoteCam({ state, commit }, camId) {
    const tempRemoteCamsList = [...state.remoteCamsList];
    const [error] = await RemoteDeviceService.deleteRemoteCam(camId);
    if (error) {
      toast.info("there's some error while deleting remote cam device");
      return;
    }
    toast.success('remote cam device deleted');

    const updatedCamsList = tempRemoteCamsList.filter(
      camDevice => camDevice.id != camId
    );
    console.log('inside delete action', updatedCamsList);

    commit(types.SET_REMOTE_CAMS_LIST, updatedCamsList);
  },

  async updateRemoteCamDetails({ state, commit }, payload) {
    const tempRemoteCamsList = [...state.remoteCamsList];
    const [error] = await RemoteDeviceService.updateRemoteCamDetails(payload);
    if (error) {
      toast.info("there's some error while updating remote cam details");
      return;
    }
    toast.success('details updated for remote cam');

    const camDeviceIndex = tempRemoteCamsList.findIndex(
      camDevice => camDevice.id === payload.id
    );
    tempRemoteCamsList[camDeviceIndex] = payload;

    commit(types.SET_REMOTE_CAMS_LIST, tempRemoteCamsList);
  },

  async getRemoteDevices({ commit }) {
    commit(types.SET_IS_FETCHING_CAMS_LIST, true);
    const [error, data] = await RemoteDeviceService.fetchAllRemoteDevices(
      false
    );
    console.log({ data });
    commit(types.SET_IS_FETCHING_CAMS_LIST, false);
    if (error) {
      toast.info('Error occurred while fetching Remote Cams Devices!');
      return;
    }
    commit(types.SET_REMOTE_CAMS_LIST, data);
  },

  setIsEditRemoteDevice({ commit }, payload) {
    commit(types.SET_IS_EDIT_REMOTE_DEVICE, payload);
  }
};

const mutations = {
  [types.SET_REMOTE_CAMS_LIST](state, payload) {
    state.remoteCamsList = payload;
  },
  [types.SET_IS_FETCHING_CAMS_LIST](state, payload) {
    state.isFetchingCamsList = payload;
  },
  [types.SET_SHOW_REMOTE_CAM_MODAL](state, payload) {
    state.showRemoteCamModal = payload;
  },
  [types.SET_REMOTE_CAM_MODAL_LOADER](state, payload) {
    state.remoteCamModalLoader = payload;
  },
  [types.SET_IS_EDIT_REMOTE_DEVICE](state, payload) {
    state.isEditRemoteDevice = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
