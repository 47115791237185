export const localStorageItems = [
  'id',
  'name',
  'role',
  'organization',
  'token',
  'timestamp',
  'hostURL',
  'poc',
  'isPolicyAccepted',
  'fcmToken',
  'email',
  'sessionEndTime',
  'sessionId',
  'sessionUser',
  'sessionKey',
  'refresh',
  'loglevel',
  'require_otp',
  'isOtpSetup',
  'azureLogin',
];

export const itemsToKeep = ['hostURL', 'redirectUrl'];

export const removeUnwantedLocalStorageItems = () => {
  localStorageItems.forEach((item) => {
    if (!itemsToKeep.includes(item)) {
      localStorage.removeItem(item);
    }
  });
};
