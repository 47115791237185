import dayjs from 'dayjs';
import { dateTimeFormat, timeFormat } from 'src/config/date-format-config';
import moment from 'moment';

const defaultFormat = 'MM/DD/YY, HH:mm:ss';

export default {
  getLocalDate(date = new Date().toISOString(), format = dateTimeFormat) {
    const _date = `${dayjs(date).format(format)}`;
    return _date;
  },

  reformatDate(date) {
    const temp = new Date(date).toLocaleDateString().split('/');
    temp.unshift(temp.pop());
    return temp.join('-');
  },

  formatDate(dateString, format = defaultFormat) {
    if (!dateString) return '';
    return dayjs(dateString).format(format);
  },

  dateDifference(date1, date2, unit, isDecimal = false) {
    const d1 = dayjs(date1);
    const d2 = dayjs(date2);
    return d1.diff(d2, unit, isDecimal);
  },

  getFormattedDate(date_value) {
    const date = new Date(date_value);
    // const date = date_value;
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }

    const formattedDate = year + '-' + month + '-' + dt;
    return formattedDate;
  },

  getTimeFromDate(dateString) {
    if (!dateString) return '';
    const time = dateString?.split('T').pop().replace('Z', '');
    return time && dayjs(time, timeFormat);
  },
  _getDate(date = new Date()) {
    const hoursToAdd = date.toString().split('GMT').pop().split(' ')[0] / 100;
    return dayjs(date.toISOString(), dateTimeFormat).add(hoursToAdd, 'hour');
  },

  subtractDays(date = new Date(), daysToAdd = 0) {
    const hoursToAdd = date.toString().split('GMT').pop().split(' ')[0] / 100;

    return dayjs(date.toISOString(), dateTimeFormat)
      .add(hoursToAdd, 'hour')
      .subtract(daysToAdd, 'day');
  },

  subtractMonths(date = new Date(), daysToAdd = 0) {
    const hoursToAdd = date.toString().split('GMT').pop().split(' ')[0] / 100;

    return dayjs(date.toISOString(), dateTimeFormat)
      .add(hoursToAdd, 'hour')
      .subtract(daysToAdd, 'month');
  },

  getTodayStartTime() {
    const currentTime = new Date();
    const hoursToAdd =
      currentTime.toString().split('GMT').pop().split(' ')[0] / 100;

    const startOfToday = dayjs(currentTime).startOf('day'); // Start of today's date
    // .add(hoursToAdd, 'hour');

    return startOfToday;
  },

  convertUTCToLocal(utcDate) {
    const localDate = new Date(utcDate.toString());
    return moment(localDate).format('HH:mm');
  },

  convertLocalToUTC(localDate) {
    const utcDate = new Date(localDate);
    const isoString = utcDate.toISOString();
    return isoString.slice(0, 19) + 'Z';
  },
  
  getStartEndTimeForSchedule(startEndTime = null) {
    if (!startEndTime) return [null, null];
    
    let start_time = null;
    let end_time = null;
    
    [start_time, end_time] = startEndTime;
         
    if (!start_time.$d && !end_time.$d) {
      toast.info('Schedule time is not properly selected!');
      isScheduling.value = false;
      return [null, null];
    }
    const startDate = this.getFormattedDate(start_time.$d);
    const endDate = this.getFormattedDate(end_time.$d);
    const [startTime] = new Date(start_time.$d).toTimeString().split(' ');
    const [endTime] = new Date(end_time.$d).toTimeString().split(' ');
    return [startDate + 'T' + startTime, endDate + 'T' + endTime];
  },

  getTimeFromSeconds(timeInSeconds) {
    const seconds = timeInSeconds;
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return dayjs().hour(hours).minute(minutes).second(remainingSeconds);
  },
};
