export default [
  {
    path: '/login',
    name: 'Login',
    component: () =>
      window.location.hostname === 'admin.retrocausal.tech'
        ? import('src/components/auth/AdminPanelLogin.vue')
        : import('src/components/auth/Login.vue'),
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('src/components/auth/Signup.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Policy',
    component: () => import('src/components/auth/PrivacyPolicyV2.vue'),
  },
  {
    path: '/resend-verification',
    name: 'Resend Verification Email',
    component: () => import('src/components/auth/RequestVerifyEmail.vue'),
  },
  {
    path: '/verify-email/:token',
    name: 'Email Verification',
    component: () => import('src/components/auth/VerifyEmail.vue'),
  },
  // {
  //   path: '/otp-selection',
  //   name: 'OTP Selection',
  //   component: () => import('src/components/auth/OTPSelection.vue'),
  // },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import('src/components/auth/ForgotPassword.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'Reset Password',
    component: () => import('src/components/auth/ResetPassword.vue'),
  },
  {
    path: '/change-password',
    name: 'Change Password',
    component: () => import('src/components/auth/ChangePassword.vue'),
  },
];
