import TaskObjectService from 'src/services/taskObjectsMarking';
import { useToast } from 'vue-toastification';
import types from '../mutation-types';

const toast = useToast();

const state = {
  loadingImages: false,
  imagesForAnnotation: [],
  totalImagesForAnnotation: 0,
  prevPageLastImageObj: null,
  prevGroups: null,
  prevPolygons: null,
};

const getters = {
  loadingImages: (state) => state.loadingImages,
  imagesForAnnotation: (state) => state.imagesForAnnotation,
  totalImagesForAnnotation: (state) => state.totalImagesForAnnotation,
  prevPageLastImageObj: (state) => state.prevPageLastImageObj,
  prevGroups: (state) => state.prevGroups,
  prevPolygons: (state) => state.prevPolygons,
};

const actions = {
  async loadImagesForAnnotation({ commit, dispatch }, payload) {
    commit(types.SET_ANNOTATION_IMAGES_LOADING, true);
    const [error, data] = await TaskObjectService.getObjects(payload);
    commit(types.SET_ANNOTATION_IMAGES_LOADING, false);
    if (error) {
      toast.error('error in getting images');
      return;
    }
    commit(types.SET_IMAGES_FOR_ANNOTATION, [...data.results]);
    commit(types.SET_TOTAL_ANNOTATION_IMAGES, data.count);
  },
  setAnnotationImagesList({ commit }, payload) {
    commit(types.SET_IMAGES_FOR_ANNOTATION, [...payload]);
  },
  setAnnotationImagesListCount({ commit }, payload) {
    commit(types.SET_TOTAL_ANNOTATION_IMAGES, payload);
  },
  setListLastImageObject({ commit, state }) {
    const obj = state.imagesForAnnotation.at(-1);
    commit(types.SET_PREV_PAGE_LAST_IMAGE_OBJ, obj);
  },
  setPrevGroups({ commit }, payload) {
    commit(types.SET_PREV_GROUPS, payload);
  },
  setPrevPolygons({ commit }, payload) {
    commit(types.SET_PREV_POLYGONS, payload);
  },
  resetAnnotationImagesList({ commit, dispatch }) {
    dispatch('setAnnotationImagesList', []);
    dispatch('setAnnotationImagesListCount', 0);
  },
};

const mutations = {
  [types.SET_ANNOTATION_IMAGES_LOADING](state, payload) {
    state.loadingImages = payload;
  },
  [types.SET_IMAGES_FOR_ANNOTATION](state, payload) {
    state.imagesForAnnotation = payload;
  },
  [types.SET_TOTAL_ANNOTATION_IMAGES](state, payload) {
    state.totalImagesForAnnotation = payload;
  },
  [types.SET_PREV_PAGE_LAST_IMAGE_OBJ](state, payload) {
    state.prevPageLastImageObj = payload;
  },
  [types.SET_PREV_GROUPS](state, payload) {
    state.prevGroups = payload;
  },
  [types.SET_PREV_POLYGONS](state, payload) {
    state.prevPolygons = payload;
  },
};

export default {
  getters,
  actions,
  mutations,
  state,
};
