import ApiHelper from './index';

const getTraceVideos = async (spinner = true, queryParams) => {
  return await ApiHelper.get('telemetry/trace/', spinner, queryParams);
};

const getTraceVideosV2 = async (spinner = true, queryParams) => {
  return await ApiHelper.get('telemetry/trace/v2', spinner, queryParams);
};

const fetchCyclesDetails = async (payload, spinner = true) => {
  return await ApiHelper.post('telemetry/fetch_cycles/', payload, spinner);
};

const fetchCyclesDetailsV2 = async (spinner = true, queryParams) => {
  return await ApiHelper.get('telemetry/fetch_cycles/v2', spinner, queryParams);
};

const fetchMetaData = async (queryParams, spinner = false) => {
  return await ApiHelper.get(
    '/telemetry/unique_metadata_keys/',
    spinner,
    queryParams
  );
};

const fetchTopFivePercentile = async (spinner = true, queryParams) => {
  return await ApiHelper.get(
    'telemetry/top_five_percentile',
    spinner,
    queryParams
  );
};

const getTraceCycleRecordData = async (queryParams, spinner = false) => {
  return await ApiHelper.get(
    'telemetry/trace/trace_single_cycle_details/',
    spinner,
    queryParams
  );
};

const archiveCycle = async (cycleId) => {
  return await ApiHelper.remove(`/telemetry/cycle/${cycleId}/`);
};

const revertCycle = async (id, payload) => {
  return await ApiHelper.patch(`telemetry/cycle/${id}`, payload, false);
};

const addTimeStampTags = async (payload) => {
  return await ApiHelper.post(
    `organization/tag_time_stamp_videos_view`,
    payload,
    false
  );
};

const getTimeStampTags = async (taskRecordId) => {
  return await ApiHelper.get(`organization/tag_time_stamp_videos_view`, false, {
    task_record: taskRecordId,
  });
};

const removeTimeStampTags = async (id) => {
  return await ApiHelper.remove(
    `organization/tag_time_stamp_video/${id}`,
    false
  );
};

const markVideoValidate = async (payload) => {
  return await ApiHelper.post(
    'organization/validation-videos/',
    payload,
    false
  );
};

const fetchValidatedVideos = async (payload) => {
  return await ApiHelper.get(
    'organization/validation-videos/retrieve',
    false,
    payload
  );
};
const getLineTrace = async () => {
  return await ApiHelper.get('telemetry/trace/line/');
};

const addLineTrace = async (payload) => {
  return await ApiHelper.post('telemetry/trace/line/', payload);
};

const updateLineTrace = async (id, payload) => {
  return await ApiHelper.patch(`telemetry/trace/line/${id}/`, payload);
};

const removeLineTrace = async (id) => {
  return await ApiHelper.remove(`telemetry/trace/line/${id}/`);
};

const getLineTraceCycles = async (params) => {
  return await ApiHelper.get(
    'telemetry/trace/line_traceability/',
    true,
    params
  );
};

export default {
  getTraceVideos,
  fetchCyclesDetails,
  getTraceVideosV2,
  fetchCyclesDetailsV2,
  fetchTopFivePercentile,
  archiveCycle,
  getTraceCycleRecordData,
  fetchMetaData,
  revertCycle,
  addTimeStampTags,
  getTimeStampTags,
  removeTimeStampTags,
  markVideoValidate,
  fetchValidatedVideos,
  getLineTrace,
  addLineTrace,
  updateLineTrace,
  removeLineTrace,
  getLineTraceCycles,
};
