import { roles } from 'src/config/roles-config.js';

export default [
  {
    path: '/admin',
    name: 'Admin Layout',
    component: () => import('src/components/core/LayoutV2.vue'),
    children: [
      {
        path: 'tampering-requests',
        name: 'Tampering Requests',
        component: () =>
          import('src/components/admin-panel/pages/TamperingRequests/Index'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'organizations',
        name: 'Organizations',
        component: () =>
          import('src/components/admin-panel/pages/Organization/Index'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'users',
        name: 'All Users',
        component: () =>
          import('src/components/admin-panel/pages/ApprovedUser/Index'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'roles',
        name: 'Roles',
        component: () => import('src/components/admin-panel/pages/Roles/Index'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'pending-users',
        name: 'Pending Users',
        component: () =>
          import('src/components/admin-panel/pages/PendingUser/Index'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'devices',
        name: 'All Devices',
        component: () =>
          import('src/components/admin-panel/pages/Device/Index'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'archived-cycles',
        name: 'Archived Cycles',
        component: () =>
          import('src/components/admin-panel/pages/ArchivedCycles/Index'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'tasks',
        name: 'All Operations',
        component: () => import('src/components/admin-panel/pages/Task/Index'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'updates',
        name: 'Updates',
        component: () =>
          import('src/components/admin-panel/pages/AutoUpdates/Index'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'instances',
        name: 'Instances',
        component: () =>
          import('src/components/admin-panel/pages/Instances/Index'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'trained-tasks',
        name: 'Trained Operations',
        component: () =>
          import('src/components/admin-panel/pages/TrainedTasks/Index'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'annotations',
        name: 'Annotations',
        component: () =>
          import('src/components/admin-panel/pages/Annotation/Index'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'labelers',
        name: 'Labelers',
        component: () =>
          import('src/components/admin-panel/pages/Labelers/Index'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'task-videos/:taskId/:taskName',
        name: 'Task Videos',
        props: true,
        component: () =>
          import(
            'src/components/labeler-panel/pages/VideosList/VideosList.vue'
          ),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'user-report',
        name: 'User Report',
        component: () =>
          import('src/components/admin-panel/pages/UserReport/Index.vue'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'barcode',
        name: 'Barcode',
        component: () =>
          import('src/components/admin-panel/pages/Barcode/Index.vue'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'aws-token',
        name: 'AWS-Lambda Token',
        component: () =>
          import('src/components/admin-panel/pages/AwsToken/Index.vue'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'feature-control',
        name: 'Feature Control',
        component: () =>
          import('src/components/admin-panel/pages/FeatureControl/Index.vue'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'model-versions',
        name: 'Org Model Versions',
        component: () =>
          import('src/components/admin-panel/pages/ModelVersions/Index.vue'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'model-types',
        name: 'Model Types',
        component: () =>
          import('src/components/admin-panel/pages/ModelTypes/Index.vue'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: 'event-notification',
        name: 'Event Notification',
        component: () =>
          import(
            'src/components/admin-panel/pages/EventNotification/Index.vue'
          ),
        meta: { authorize: [roles.admin] },
      },
    ],
  },
];
