import router from '@/router';
import allSidebarItems from 'src/config/drawer-items-config.js';
import { featureLabels } from 'src/config/features-config.js';
import { rolesDrawerItems } from 'src/config/roles-config.js';
import AuthService from 'src/services/auth';
import {
  isUserAssignedToOrg,
  setEmailForResendVerification,
  setRecieveOtpOn,
  setUserDetails,
} from 'src/utils/auth';
import { customErrorHandler, handleError } from 'src/utils/error';
import { useToast } from 'vue-toastification';
import { setOTPStatus, setOTPVerificationStatus } from '../../utils/auth';
import types from '../mutation-types';
// import {}
import { PublicClientApplication } from '@azure/msal-browser';
import fcmstore from 'src/store';

import {
  localStorageItems,
  removeUnwantedLocalStorageItems,
} from 'src/config/auth-config';
import { emitter } from 'src/main';
import {
  isBaseUrl,
  isOrgUrl,
  redirectFromBaseToOrgUrl,
  redirectFromOrgToBaseUrl,
} from 'src/router';
import { teardownFCM } from 'src/utils/fcm';

const toast = useToast();

export async function azureRefreshToken(payload) {
  console.log('azureRefreshToken', payload);
  const [error, data] = await AuthService.azureRefreshToken(payload);
  // if (error) return customErrorHandler(error?.response);
}
export async function refreshJWTToken() {
  const refreshToken = localStorage.getItem('refresh');
  const payload = { refresh: refreshToken };
  const [error, data] = await AuthService.refreshJWTToken(payload);
  localStorage.setItem('token', data.access);
  localStorage.setItem('refresh', data.refresh);
  return data.access;
}

const getters = {
  user: (state) => state.user,
  token: (state) => state.token,
  refresh: (state) => state.refresh,
  isTokenSet: (state) => state.isTokenSet,
  organization: (state) => state.organization,
  userOrganizations: (state) => state.userOrganizations,
  role: (state) => state.role,
  sidebarItems: (state, getters, rootState, rootGetters) => {
    let barItems = allSidebarItems.filter((item) =>
      rolesDrawerItems[state.role]?.includes(item.name)
    );
    let featuresExistInOrg = rootState.featureControl.featureSubscriptions.map(
      (item) => {
        if (!item?.Feature?.suppressed) {
          return featureLabels[item?.Feature?.name];
        }
      }
    );
    let allFeatures = Object.values(featureLabels);
    barItems = barItems.filter((item) => {
      if (
        allFeatures?.includes(item.name) &&
        !featuresExistInOrg?.includes(item.name)
      ) {
        return false;
      }
      return true;
    });
    return barItems;
  },
  hostURL: (state) => state.hostURL,
  poc: (state) => state.poc,
  isPolicyAccepted: (state) => state.isPolicyAccepted,
  accessToken: (state) => state.accessToken,
  msalInstance: (state) => state.msalInstance,
  azureLogin: (state) => state.azureLogin,
  isOtpSetup: (state) => state.isOtpSetup,
  email: (state) => state.email,
  require_otp: (state) => state.require_otp,
  organizationUsers: (state) => state.organizationUsers,
  receiveOtpOn: (state) => state.receiveOtpOn,
  isGoogleAuthActivated: (state) => state.isGoogleAuthActivated,
  hasMobileNumber: (state) => state.hasMobileNumber,
};

const actions = {
  async createMsalInstance({ commit }) {
    const msalConfig = {
      auth: {
        clientId: 'c5093c91-21ae-4374-9be1-d95791ea78eb',
        authority: 'https://login.microsoftonline.com/organizations',
      },
      cache: {
        cacheLocation: 'localStorage',
      },
    };
    let msalInstance = new PublicClientApplication(msalConfig);
    commit(types.SET_MSAL_INSTANCE, msalInstance);
  },

  async googleSignIn({ commit, dispatch }, payload) {
    const [error, data] = await AuthService.googleSignIn(payload);
    if (error) {
      toast.error(error.response.data.response);
      commit(types.LOGOUT);
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem('isOtpSetup', false);
      localStorage.setItem('require_otp', true);
      return;
    }

    if (data.response === 'User created successfully') {
      setTimeout(toast.success(data.response), 3000);
      commit(types.LOGOUT);
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem('isOtpSetup', false);
      localStorage.setItem('require_otp', true);
      return;
    } else {
      if (await isUserAssignedToOrg(data))
        return toast.error('User is not assigned to any organization');
    }

    let userInfo = { ...data, email: payload.username };
    let token = payload.token;
    commit(types.SET_TEMPORARY_USER_INFO, { token, id: userInfo.id });
    setUserDetails({ ...data, email: payload.username }, commit);

    // commit(types.SAVE_ACCESS_TOKEN, payload.access_token);
    // commit(types.SET_AZURE_LOGIN, true);

    await setOTPVerificationStatus(commit);
    await dispatch('fetchOrganizations');
    return data;
  },

  async adminSignIn({ commit, dispatch }, payload) {
    const [error, data] = await AuthService.adminSignIn(payload);
    if (error) {
      toast.error(error.response.data.response);
      commit(types.LOGOUT);
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem('isOtpSetup', false);
      localStorage.setItem('require_otp', true);
      return;
    }

    if (data.response === 'User created successfully') {
      setTimeout(toast.success(data.response), 3000);
      commit(types.LOGOUT);
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem('isOtpSetup', false);
      localStorage.setItem('require_otp', true);
      return;
    } else {
      if (await isUserAssignedToOrg(data))
        return toast.error('User is not assigned to any organization');
    }

    let userInfo = { ...data, email: payload.username };
    let token = payload.token;
    commit(types.SET_TEMPORARY_USER_INFO, { token, id: userInfo.id });
    setUserDetails({ ...data, email: payload.username }, commit);

    // commit(types.SAVE_ACCESS_TOKEN, payload.access_token);
    // commit(types.SET_AZURE_LOGIN, true);

    await setOTPVerificationStatus(commit);
    if (!data.is_admin) {
      await dispatch('fetchOrganizations');
    } else {
      router.replace({ path: '/' });
    }
    return data;
  },

  async azureSignIn({ commit, dispatch }, payload) {
    const [error, data] = await AuthService.azureSignIn(payload);
    if (error) {
      toast.error(error.response.data.response);
      dispatch('resetAuthState');
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem('isOtpSetup', false);
      localStorage.setItem('require_otp', true);
      return;
    }

    if (data.response === 'User created successfully') {
      setTimeout(toast.success(data.response), 3000);
      dispatch('resetAuthState');
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem('isOtpSetup', false);
      localStorage.setItem('require_otp', true);
      return;
    } else {
      if (await isUserAssignedToOrg(data))
        return toast.error('User is not assigned to any organization');
    }

    let userInfo = { ...data, email: payload.username };
    let token = payload.token;
    commit(types.SET_TEMPORARY_USER_INFO, { token, id: userInfo.id });
    await setUserDetails({ ...data, email: payload.username }, commit);
    localStorage.setItem('azureLogin', true);

    setUserDetails({ ...data, email: payload.username }, commit);

    // commit(types.SAVE_ACCESS_TOKEN, payload.access_token);
    // commit(types.SET_AZURE_LOGIN, true);

    await setOTPVerificationStatus(commit);
    await dispatch('fetchOrganizations');
    return;
  },
  async azureSignUp({ commit }, payload) {
    const [error, data] = await AuthService.azureSignUp(payload);
    if (error) {
      toast.error(error.response);
      return;
    }
    toast.success(
      'Admin has been notified. Your account will be approved within 24 Hours.'
    );
    setTimeout(() => router.replace({ name: 'Login' }), 3000);
  },
  async userSignup(_, payload) {
    const [error] = await AuthService.signUp(payload);
    if (error) return handleError(error?.response);

    toast.success(
      'Verification email has been sent please verify to complete registeration!'
    );
    setTimeout(() => router.replace({ name: 'Login' }), 3000);
  },
  async resendVerificationEmail(_, payload) {
    const [error, data] = await AuthService.resendVerificationEmail(payload);
    if (error) return handleError(error?.response);

    toast.success(
      'Verification email has been sent again verify to complete registeration!'
    );
  },
  async verifyEmail(_, payload) {
    const [error, data] = await AuthService.verifyEmail(payload);
    if (error) {
      toast.error('Token expired/Invalid!');
      return false;
    }
    toast.success(data.response[0]);
    setTimeout(() => router.replace({ name: 'Login' }), 3000);
    return true;
  },

  async userLogin({ commit, dispatch }, payload) {
    // start implementation here
    // debugger;
    const [error, data] = await AuthService.signIn(payload);

    if (error) {
      setEmailForResendVerification(payload.username, commit);
      handleError(error?.response);
      return false;
    }
    if (await isUserAssignedToOrg(data)) {
      if (error.response.status === 429) {
        return toast.error('Too many attempts.Please try after 1 min.');
      }
      return handleError(error?.response);
    }

    let userInfo = { ...data, email: payload.email };
    let token = payload.token;
    commit(types.SET_TEMPORARY_USER_INFO, { token, id: userInfo.id });
    await setUserDetails({ ...userInfo }, commit);

    commit(types.SET_AZURE_LOGIN, false);

    await setOTPStatus(commit);

    emitter.emit('chatbot-visibility', true);

    if (!data.require_otp) {
      await setOTPVerificationStatus(commit);
      // setupFCM();

      if (!data.is_admin) {
        await dispatch('fetchOrganizations');
      } else {
        router.replace({ path: '/' });
      }

      return data;
    }
    return data;
  },

  async setOTPMethod({ commit }, payload) {
    // need another account for this
    const [error, data] = await AuthService.setOTPMethod(payload);
    if (error) {
      return handleError(error?.response);
    }
    toast.success(data.response);
    setRecieveOtpOn(payload, commit);
    return true;
  },
  async setOTPMethodEmail({ commit }, payload) {
    const [error, data] = await AuthService.setOTPMethodEmail();
    if (error) {
      handleError(error?.response);
      return;
    }
    toast.success(data.response);
    setRecieveOtpOn(payload, commit);
  },
  async setOTPMethodGoogleAuth({ commit }, payload) {
    if (payload.OTP.length != 6) {
      toast.error('Please enter the 6-digit OTP');
      return false;
    }

    const [error, data] = await AuthService.setOTPMethodGoogleAuth(payload);
    if (error) {
      customErrorHandler(error?.response);
      return false;
    }

    toast.success(data.response);
    await setRecieveOtpOn({ receive_otp_on: 'googleAuth' }, commit);
    return true;
  },
  async setOTPMethodSms({ commit }, payload) {
    if (payload.OTP.length != 6) {
      toast.error('Please enter the 6-digit OTP');
      return false;
    }

    const [error, data] = await AuthService.setOTPMethodSms(payload);
    if (error) {
      customErrorHandler(error?.response);
      return false;
    }

    toast.success(data.response);
    await setRecieveOtpOn({ receive_otp_on: 'sms' }, commit);
    return true;
  },
  async verifyOTP({ commit, dispatch }, payload) {
    if (payload.OTP.length != 6) {
      toast.error('Please enter the 6-digit OTP');
      return false;
    }

    const [error, data] = await AuthService.verifyOTP(payload);
    if (error) {
      customErrorHandler(error?.response);
      return false;
    }

    await setOTPVerificationStatus(commit);
    await dispatch('fetchOrganizations');

    return true;
  },
  async fetchOrganizations({ commit, dispatch }) {
    let params = { user_id: localStorage.getItem('id') };

    params['user_id'] = JSON.parse(
      localStorage.getItem('persistUserInfoTemporary')
    ).id;

    if (!params.user_id || !localStorage.getItem('sessionKey')) {
      dispatch('clearState');
      return;
    }

    const [error, data] = await AuthService.GetUserLinkedOrgByUserId(params);
    if (error) {
      customErrorHandler(error?.response);
      return;
    }
    commit(types.SET_ORGANIZATIONS, data);
    localStorage.setItem('organizationsCount', data.length);
    // remove these right after getting user linked organization, so that if user refresh page he couldn't access home page
    // localStorage.removeItem('token');
    // localStorage.removeItem('id');
    // return;
    if (data.length == 1) {
      let payload = {
        organization: data[0].organization,
      };
      // commit(types.SET_ORGANIZATIONS, data);
      dispatch('selectOrganization', payload);
    } else {
      commit(types.SET_ORGANIZATIONS, data);
      // remove these right after getting user linked organization, so that if user refresh page he couldn't access home page
      localStorage.removeItem('token');
      localStorage.removeItem('id');
    }
  },

  async selectOrganization({ commit }, payload) {
    await AuthService.SwitchOrganizationByUser(payload);

    let temporaryInfo =
      state.persistUserInfoTemporary ||
      JSON.parse(localStorage.getItem('persistUserInfoTemporary'));
    localStorage.setItem('organization', payload.organization);
    localStorage.setItem('token', temporaryInfo.token);
    localStorage.setItem('id', temporaryInfo.id);
    commit(types.SAVE_TOKEN, temporaryInfo.token);
    commit(types.SAVE_ORGANIZATION, payload.organization);

    if (isBaseUrl()) {
      redirectFromBaseToOrgUrl();
      return;
    } else {
      router.replace({ path: '/' }).then(() => {
        commit(types.SET_ORGANIZATIONS, []);
      });
    }
  },

  async addUserToMultipleOrgs({ commit }, payload) {
    await AuthService.AddUserToMultipleOrgs(payload);
  },

  async removeUserFromMultipleOrgs({ commit }, params) {
    await AuthService.RemoveUserFromMultipleOrgs(params);
  },

  async resendOTP(_) {
    const [error, data] = await AuthService.resendOtp();
    if (error) return customErrorHandler(error?.response);

    toast.success('OTP Sent!');
  },
  async forgotPassword(_, payload) {
    const [error, data] = await AuthService.forgotPassword(payload);
    if (error) return customErrorHandler(error?.response);

    toast.success(data.response);
    router.replace({ name: 'Login' }, 3000);
  },

  async changePassword(_, payload) {
    const [error, data] = await AuthService.changePassword(payload);
    if (error) return customErrorHandler(error?.response);

    toast.success(data.response);
    router.replace({ name: 'Login' }, 3000);
  },

  async RequestChangePassword(_, payload) {
    const [error, data] = await AuthService.RequestChangePassword(payload);
    if (error) {
      customErrorHandler(error?.response);
      return false;
    }

    toast.success(data.response);
    return true;
  },

  async RequestChangeEmail(_, payload) {
    const [error, data] = await AuthService.RequestChangeEmail(payload);
    if (error) {
      customErrorHandler(error?.response);
      return false;
    }

    toast.success(data.response);
    return true;
  },
  async VerifyEmailChange(_, payload) {
    const [error, data] = await AuthService.VerifyEmailChange(payload);
    if (error) {
      customErrorHandler(error?.response);
      return false;
    }

    toast.success(data.response);
    return true;
  },

  clearState({ commit, dispatch }, data = {}) {
    const { fromBasePage = false, logout = false } = data;
    if (!fromBasePage) {
      data['fromBasePage'] = false;
    }
    if (!logout) {
      data['logout'] = false;
    }
    if (
      window.location.pathname == '/organizations' &&
      !fromBasePage &&
      localStorage.getItem('sessionKey')
    ) {
      return;
    }

    let organization = localStorage.getItem('organization');
    // state.isTokenSet &&
    // !(isOrgUrl(organization) || window.location.hostname == '127.0.0.1')
    if (logout) {
      AuthService.logout();
    }
    dispatch('resetAuthState');
    removeUnwantedLocalStorageItems();
    sessionStorage.clear();
    teardownFCM();
    localStorage.setItem('isOtpSetup', false);
    localStorage.setItem('require_otp', true);

    if (isOrgUrl(organization) || window.location.hostname == '127.0.0.1') {
      redirectFromOrgToBaseUrl(logout);
      return;
    }
    router.replace({ name: 'Login' });
    commit(types.SET_ORGANIZATIONS, []);
  },

  resetAuthState({ commit }) {
    commit(types.SAVE_USER, null);
    commit(types.SET_TOKEN, null);
    commit(types.SET_IS_TOKEN_FLAG, false);
    commit(types.SAVE_ORGANIZATION, null);
    commit(types.SAVE_ROLE, null);
    commit(types.SAVE_HOSTURL, null);
    commit(types.SET_OTP_FLAG, false);
    commit(types.SET_REQUIRE_OTP, true);
    commit(types.SET_RECEIVE_OTP_ON, null);
    commit(types.SET_IS_GOOGLE_AUTH_ACTIVATED, null);
    commit(types.SET_HAS_MOBILE_NUMBER, null);
    commit(types.SET_EMAIL, null);
    commit(types.SET_AZURE_LOGIN, null);
    commit(types.SAVE_REFRESH_TOKEN, null);

    fcmstore.dispatch('setFCMToken', null);
  },

  async userLogout({ commit, dispatch }) {
    teardownFCM();

    localStorageItems.forEach((el) => localStorage.removeItem(el));

    // AuthService.logout();

    dispatch('resetAuthState');
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem('isOtpSetup', false);
    localStorage.setItem('require_otp', true);
    commit(types.SET_FCM_TOKEN, null);

    router.replace({ name: 'Login' });
  },

  async userRedirectPasswordExpired({ commit }) {
    router.push({ name: 'Change Password' });
    window.location = '/change-password';
  },

  async handleAcceptPolicy({ state, dispatch }) {
    dispatch('removePolicyFlag');
    const payload = {
      terms_and_conditions_accepted: true,
    };
    const [error] = await AuthService.acceptPolicy(state.email, payload);
    if (error) return handleError(error?.response);
  },

  async fetchAdminPermissions() {
    console.log('getting permission');
    const [error, data] = await AuthService.getPermissions();
    if (data.response == 'true') {
      return true;
    } else {
      return false;
    }
  },

  removePolicyFlag({ commit }) {
    localStorage.removeItem('isPolicyAccepted');
    commit(types.SET_POLICY_FLAG, null);
  },

  async getOrganizationUsers({ commit }) {
    let organization = localStorage.getItem('organization');
    let resp = await AuthService.GetOrganizationUsers(organization);
    commit(
      types.GET_ORGANIZATION_USERS,
      resp[1].results.map((data) => ({
        id: data.user.id,
        value: `${data.user.username} ${data.user.lastname}`,
      }))
    );
  },
};

const mutations = {
  [types.GET_ORGANIZATION_USERS](state, payload) {
    state.organizationUsers = [...payload];
  },
  [types.SET_RECEIVE_OTP_ON](state, receive_otp_on) {
    state.receiveOtpOn = receive_otp_on;
  },
  [types.SET_TOKEN](state, token) {
    state.token = token;
  },
  [types.SET_ORGANIZATIONS](state, organizations) {
    state.userOrganizations = organizations;
  },
  [types.SET_TEMPORARY_USER_INFO](state, userToken) {
    localStorage.setItem('persistUserInfoTemporary', JSON.stringify(userToken));
    state.persistUserInfoTemporary = userToken;
  },
  [types.SAVE_TOKEN](state, token) {
    state.token = token;
    state.isTokenSet = true;
  },
  [types.SET_IS_TOKEN_FLAG](state, is_token_set) {
    state.isTokenSet = is_token_set;
  },
  [types.SAVE_USER](state, user) {
    state.user = user;
  },
  [types.SAVE_ORGANIZATION](state, organization) {
    state.organization = organization;
  },
  [types.SAVE_ROLE](state, role) {
    state.role = role;
  },
  [types.SAVE_HOSTURL](state, hostURL) {
    state.hostURL = hostURL;
  },
  [types.SET_POC_FLAG](state, poc_flag) {
    state.poc = poc_flag;
  },
  [types.SET_POLICY_FLAG](state, policy_flag) {
    state.isPolicyAccepted = policy_flag;
  },
  [types.SAVE_ACCESS_TOKEN](state, token) {
    localStorage.setItem('azureLogin', true);
    state.isTokenSet = true;
  },
  [types.SET_MSAL_INSTANCE](state, msalInstance) {
    state.msalInstance = msalInstance;
  },
  [types.SET_AZURE_LOGIN](state, azureLogin) {
    state.azureLogin = azureLogin;
  },
  [types.SET_EMAIL](state, email) {
    state.email = email;
  },
  [types.SET_OTP_FLAG](state, otp_flag) {
    state.isOtpSetup = otp_flag;
  },
  [types.SET_REQUIRE_OTP](state, flag) {
    state.require_otp = flag;
  },
  [types.SET_RECEIVE_OTP_ON](state, receive_otp_on) {
    state.receiveOtpOn = receive_otp_on;
  },
  [types.SET_IS_GOOGLE_AUTH_ACTIVATED](state, is_google_auth_activated) {
    state.isGoogleAuthActivated = is_google_auth_activated;
  },
  [types.SET_HAS_MOBILE_NUMBER](state, has_mobile_number) {
    state.hasMobileNumber = has_mobile_number;
  },
  [types.SAVE_REFRESH_TOKEN](state, token) {
    state.refresh = token;
  },
};

function eraseCookie(name) {
  document.cookie = name + '=; Max-Age=-99999999;';
}

function getCookie(cName) {
  const name = cName + '=';
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split('; ');
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}

const state = {
  persistUserInfoTemporary: null,
  user: localStorage.getItem('name'),
  email: localStorage.getItem('email'),
  token: localStorage.getItem('token'),
  isTokenSet: !!localStorage.getItem('token'),
  organization: localStorage.getItem('organization'),
  userOrganizations: [],
  role: localStorage.getItem('role'),
  hostURL: localStorage.getItem('hostURL'),
  poc: localStorage.getItem('poc'),
  isPolicyAccepted: localStorage.getItem('isPolicyAccepted'),
  isOtpSetup: localStorage.getItem('isOtpSetup'),
  require_otp: localStorage.getItem('require_otp'),
  receiveOtpOn: localStorage.getItem('receiveOtpOn'),
  isGoogleAuthActivated: false,
  azureLogin: localStorage.getItem('azureLogin'),
  organizationUsers: [],
};

export default {
  state,
  getters,
  actions,
  mutations,
};
