import TaskService from 'src/services/tasks';
import VirtualTaskService from 'src/services/virtualTasks';
import { getIndexToStepMapping, getTaskNameAndProcess } from 'src/utils/task';
import types from '../mutation-types';

const state = {
  virtualTasks: [],
  selectedVirtualTask: null,
  virtualTaskNegativeSteps: [],
  virtualTaskOptionalSteps: [],
  virtualTaskIndexToStepMap: {},
  isParentOfVirtualTasks: false,
};

const getters = {
  virtualTasks: (state) => state.virtualTasks,
  selectedVirtualTask: (state) => state.selectedVirtualTask,
  virtualTaskNegativeSteps: (state) => state.virtualTaskNegativeSteps,
  virtualTaskOptionalSteps: (state) => state.virtualTaskOptionalSteps,
  virtualTaskIndexToStepMap: (state) => state.virtualTaskIndexToStepMap,
  isParentOfVirtualTasks: (state) => state.isParentOfVirtualTasks,
};

const actions = {
  setSelectedVirtualTask({ commit }, taskId) {
    commit(types.SET_SELECTED_VIRTUAL_TASK, taskId);
  },

  async fetchVirtualTasks({ commit }, parentTaskId) {
    const [error, data] = await VirtualTaskService.fetchVirtualTasks(
      parentTaskId,
      false
    );
    if (error) {
      console.log('Unable to fetch Virtual Tasks!');
      return;
    }
    commit(types.SET_VIRTUAL_TASKS, data);
    commit(types.SET_IS_PARENT_OF_VIRTUAL_TASKS, data.length > 0);
  },

  async fetchVirtualTaskDetails({ commit }, taskId) {
    const [error, data] = await TaskService.fetchTaskDetails(taskId, false);
    if (error) {
      console.log('error fetch virtual task details:', error);
      return;
    }
    const { task_detail, negativeSteps, optional_steps } = data;

    const { processes } = getTaskNameAndProcess(task_detail);
    const indexToStepMapping = getIndexToStepMapping(processes);

    commit(types.SET_VIRTUAL_TASK_NEGATIVE_STEPS, negativeSteps);
    commit(types.SET_VIRTUAL_TASK_OPTIONAL_STEPS, optional_steps);
    commit(types.SET_VIRTUAL_TASK_INDEX_TO_STEP_MAP, indexToStepMapping);
  },

  resetVirtualTaskStates({ commit }) {
    commit(types.SET_VIRTUAL_TASK_NEGATIVE_STEPS, []);
    commit(types.SET_VIRTUAL_TASK_OPTIONAL_STEPS, []);
    commit(types.SET_VIRTUAL_TASK_INDEX_TO_STEP_MAP, {});
  },
};

const mutations = {
  [types.SET_VIRTUAL_TASKS](state, payload) {
    state.virtualTasks = payload;
  },
  [types.SET_SELECTED_VIRTUAL_TASK](state, payload) {
    state.selectedVirtualTask = payload;
  },
  [types.SET_VIRTUAL_TASK_NEGATIVE_STEPS](state, payload) {
    state.virtualTaskNegativeSteps = payload;
  },
  [types.SET_VIRTUAL_TASK_OPTIONAL_STEPS](state, payload) {
    state.virtualTaskOptionalSteps = payload;
  },
  [types.SET_VIRTUAL_TASK_INDEX_TO_STEP_MAP](state, payload) {
    state.virtualTaskIndexToStepMap = payload;
  },
  [types.SET_IS_PARENT_OF_VIRTUAL_TASKS](state, payload) {
    state.isParentOfVirtualTasks = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
