import { createRouter, createWebHistory } from 'vue-router';
// import routes from './routes';
import { roleRoutes, roles } from 'src/config/roles-config';

import store from 'src/store';
import routes from './routes/index';
const router = createRouter({
  routes: routes,
  history: createWebHistory(),
});

export const baseUrls = [
  // 'localhost:8080',
  `fix-org-name-domain-changes.dev.retrocausal.tech`,
  'test.retrocausal.tech',
  `retrocausal.tech`,
];

function getOTPAuthValues() {
  try {
    const isOtpSetup = JSON.parse(
      store.getters.isOtpSetup || localStorage.getItem('isOtpSetup')
    );
    const require_otp = JSON.parse(
      store.getters.require_otp || localStorage.getItem('require_otp')
    );

    return { isOtpSetup, require_otp };
  } catch {
    const isOtpSetup = false;
    const require_otp = false;
    return { isOtpSetup, require_otp };
  }

  // return { isOtpSetup: true, credentialCheckFlag: true };
}

export function redirectFromOrgToBaseUrl(logout) {
  const queryParams = new URLSearchParams(window.location.search);

  setTimeout(() => {
    if (queryParams.get('config')) {
      return;
    }
    if (!parseUrl().baseUrl || window.location.hostname === '127.0.0.1') {
      window.location.href = `http://localhost:8080?${
        logout ? `logout=true` : `organization=true`
      }`;
      return;
    }
    window.location.href = `https://${parseUrl().baseUrl}?${
      logout ? `logout=true` : `organization=true`
    }`;
  }, 1000);
}

export function redirectFromBaseToOrgUrl() {
  let encodedData = encodeURIComponent(JSON.stringify({ ...localStorage }));
  let host = parseUrl().baseUrl;
  let organization = localStorage.getItem('organization');
  if (host == 'localhost:8080') {
    window.location.href = `http://127.0.0.1:8080?config=${encodedData}`;
    return;
  }
  window.location.href = `https://${organization}.${host}?config=${encodedData}`;
}

export function isOrgUrl(organization) {
  return parseUrl().subdomain == `${organization}.`;
}

export function isBaseUrl() {
  return !parseUrl().subdomain && parseUrl().baseUrl;
}

function parseUrl() {
  let url = window.location.host.replace('www.', '');

  const sortedBaseUrls = [...baseUrls].sort((a, b) => b.length - a.length);
  if (
    url.endsWith('.dev.retrocausal.tech') &&
    !baseUrls.find((eachUrl) => eachUrl == url)
  ) {
    return { baseUrl: null, subdomain: null };
  }
  let baseUrl = null;
  for (const candidate of sortedBaseUrls) {
    if (url.endsWith(candidate)) {
      baseUrl = candidate;
      break;
    }
  }

  if (!baseUrl) {
    return { baseUrl: null, subdomain: null };
  }

  // Extract subdomain
  let subdomain = url.slice(0, url.length - baseUrl.length);

  if (subdomain === 'admin.' && baseUrl == 'retrocausal.tech') {
    return { baseUrl: null, subdomain: null };
  }

  if (subdomain && !subdomain.endsWith('.')) {
    let nextDotIndex = baseUrl.indexOf('.'); // Find the first `.` in baseUrl

    if (nextDotIndex !== -1) {
      subdomain += baseUrl.slice(0, nextDotIndex + 1); // Move characters until next `.` in baseUrl
      baseUrl = baseUrl.slice(nextDotIndex + 1); // Adjust baseUrl
    }
  }

  return { baseUrl, subdomain };
}

router.beforeEach(async (to, from, next) => {
  const queryParams = new URLSearchParams(window.location.search);
  if (localStorage.getItem('redirectUrl')) {
    localStorage.removeItem('redirectUrl');
  }

  if (to.path === '/login' && parseUrl().subdomain) {
    redirectFromOrgToBaseUrl(false);
    return;
  }

  if (
    isBaseUrl() &&
    localStorage.getItem('organization') &&
    !queryParams.get('logout') &&
    to.path !== '/organizations'
  ) {
    // redirectFromBaseToOrgUrl();
    if (window.location.hostname == 'localhost') {
      window.location.href = `http://${window.location.hostname}:8080/organizations`;
    } else {
      window.location.href = `http://${window.location.hostname}/organizations`;
    }
    return;
  }

  const isTokenSet =
    store.getters.isTokenSet || Boolean(localStorage.getItem('token'));
  const role = store.getters.role || localStorage.getItem('role');
  const { isOtpSetup, require_otp } = getOTPAuthValues();
  const isAzureLogin = store.getters.azureLogin;

  if (
    !require_otp &&
    isOtpSetup &&
    isTokenSet &&
    to.path.includes('/reset-password/') &&
    !queryParams.get('config') &&
    !queryParams.get('organization')
  ) {
    localStorage.clear();
    store.dispatch('changeRoute', [to.name]);
    next();
  }

  if (
    !isLoginOrSignup(to) &&
    isValidRole(role) &&
    !isTokenSet &&
    !queryParams.get('config') &&
    !queryParams.get('organization')
  ) {
    store.dispatch('clearState');
  }

  if (
    require_otp &&
    isOtpSetup &&
    isTokenSet &&
    !queryParams.get('config') &&
    !queryParams.get('organization')
  ) {
    store.dispatch('clearState');
  }

  if (
    !isAzureLogin &&
    !require_otp &&
    isOtpSetup &&
    isTokenSet &&
    to.path.includes('otp-selection')
  ) {
    return redirectToAccuratePath(role, next);
  }

  if (
    !isAzureLogin &&
    isTokenSet &&
    !isOtpSetup &&
    !to.path.includes('otp-selection') &&
    !to.path.includes('privacy-policy')
  ) {
    //return next({ name: 'OTP Selection' });
  }

  if (
    !isAzureLogin &&
    !require_otp &&
    isTokenSet &&
    isLoginOrSignup(to) &&
    !to.path.includes('privacy-policy') &&
    !to.path.includes('otp-selection')
  ) {
    return redirectToAccuratePath(role, next);
  }

  if (to.path === '/') {
    return redirectToAccuratePath(role, next);
  }

  const { authorize } = to.meta;

  if (
    !require_otp &&
    isTokenSet &&
    !authorize?.includes(role) &&
    !to.path.includes('privacy-policy') &&
    !to.path.includes('otp-selection') &&
    !to.path.includes('change-password')
  ) {
    return redirectToAccuratePath(role, next);
  }
  store.dispatch('changeRoute', [to.matched[to.matched.length - 1]].name);
  next();
});

async function removeParamsAndReroute() {
  setTimeout(() => {
    const newUrl = window.location.pathname;
    window.history.replaceState(null, '', newUrl);
    window.location.href = newUrl;
  }, 0);
}

async function handleConfigParam() {
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.get('config')) {
    const encodedData = queryParams.get('config');
    const localStorageData = JSON.parse(decodeURIComponent(encodedData));

    Object.entries(localStorageData).forEach(([key, value]) => {
      localStorage.setItem(key, value);
    });
    if (parseUrl().subdomain) {
      localStorage.setItem(
        'organization',
        parseUrl().subdomain.replace('.', '')
      );
    }
    await removeParamsAndReroute();
    return true;
  }
  return false;
}

async function handleLogoutParam(next) {
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.get('logout')) {
    await removeParamsAndReroute();
    store.dispatch('clearState');

    next({ path: '/login' });
    return true;
  }
  return false;
}

function handleRoleRedirect(role, next) {
  if (!roleRoutes[role]) {
    store.dispatch('clearState');
    if (isOrgUrl(localStorage.getItem('organization'))) {
      return;
    }
    return next({ path: '/login' });
  }

  next({ name: roleRoutes[role] });
}

async function redirectToAccuratePath(role, next) {
  if (await handleConfigParam()) return;
  if (await handleLogoutParam(next)) return;
  handleRoleRedirect(role, next);
}

function isSessionExpired() {
  var minutesDifference = calculateTimeDifference();

  if (minutesDifference > 24) {
    store.dispatch('clearState');
  }
}

function calculateTimeDifference() {
  let timestamp = localStorage.getItem('timestamp');
  let currentDate = new Date();
  let lastLogin = new Date(timestamp);

  let difference = currentDate - lastLogin;
  let minutesDifference = Math.floor(difference / 1000 / 60 / 60);
  // let minutesDifference = Math.floor(difference / 1000);
  return minutesDifference;
}

function isLoginOrSignup(to) {
  return [
    '/login',
    '/admin-login',
    '/signup',
    '/privacy-policy',
    '/resend-verification',
    '/verify-email/',
    // '/otp-selection',
    // '/verify-otp',
    '/forgot-password',
    '/reset-password',
  ].some((url) => to.path.includes(url));
}

function isValidRole(role) {
  if (!Object.values(roles).includes(role)) {
    // localStorage.setItem('redirectUrl', window.location.href);
    store.dispatch('clearState');
    return false;
  }
  return true;
}

function getClosestMatchingRouteName(matchedRoutes, currentPath) {
  let closestMatch = null;
  let closestMatchLength = -1;

  matchedRoutes.forEach((route) => {
    if (currentPath === route.path) {
      closestMatch = route.name;
      return;
    }

    const routePathSegments = route.path.split('/');
    const currentPathSegments = currentPath.split('/');

    if (routePathSegments.length <= currentPathSegments.length) {
      let isMatch = true;
      for (let i = 0; i < routePathSegments.length; i++) {
        if (
          routePathSegments[i] !== currentPathSegments[i] &&
          routePathSegments[i][0] !== ':'
        ) {
          isMatch = false;
          break;
        }
      }

      if (isMatch && routePathSegments.length > closestMatchLength) {
        closestMatch = route.name;
        closestMatchLength = routePathSegments.length;
      }
    }
  });

  return closestMatch;
}

export default router;
