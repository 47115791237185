import * as AUTH from './http/httpAuthFunction';
import * as configuration from './http/httpRequestConfig';
import * as CRUD from './http/httpCrudFunction';
import * as httpFunction from './http/httpFunction';

export function getHostUrl() {
  var hostURL = localStorage.getItem('hostURL');
  if (hostURL == null) {
    localStorage.setItem('hostURL', configuration.prodHostUrl);
    hostURL = localStorage.getItem('hostURL');
  }
  // return configuration.testHostUrl;
  // return configuration.localHostUrl;
  return hostURL;
}

const hostUrl = getHostUrl();

export default {
  get(
    baseUrl,
    spinner = true,
    log = true,
    alert = false,
    successMessage = '',
    errorMessage,
    host = hostUrl
  ) {
    const config = configuration.getAuthConfig();
    return CRUD.get(
      baseUrl,
      host,
      config,
      spinner,
      log,
      alert,
      successMessage,
      errorMessage
    );
  },

  getData(
    baseUrl,
    spinner = true,
    log = true,
    alert = false,
    successMessage = '',
    errorMessage,
    hostURL = hostUrl
  ) {
    const config = configuration.getAuthConfig();
    return CRUD.getData(
      baseUrl,
      hostURL,
      config,
      spinner,
      log,
      alert,
      successMessage,
      errorMessage
    );
  },

  async post(
    baseUrl,
    data,
    spinner = true,
    log = true,
    alert = true,
    successMessage = 'Data created successfully!',
    errorMessage = 'Error',
    host = hostUrl
  ) {
    const config = configuration.getAuthConfig();
    return await CRUD.post(
      baseUrl,
      data,
      host,
      config,
      spinner,
      log,
      alert,
      successMessage,
      errorMessage
    );
  },

  startTraining(
    baseUrl,
    data,
    spinner = true,
    log = true,
    alert = true,
    successMessage = 'Data created successfully!',
    errorMessage = 'Error'
  ) {
    const config = configuration.getAuthConfig();
    return CRUD.startTraining(
      baseUrl,
      data,
      hostUrl,
      config,
      spinner,
      log,
      alert,
      successMessage,
      errorMessage
    );
  },

  put(
    baseUrl,
    id,
    data,
    spinner = true,
    log = true,
    alert = true,
    successMessage = 'Data updated successfully!',
    errorMessage = 'Error'
  ) {
    const config = configuration.getAuthConfig();
    return CRUD.put(
      baseUrl,
      id,
      data,
      hostUrl,
      config,
      spinner,
      log,
      alert,
      successMessage,
      errorMessage
    );
  },
  patch(
    baseUrl,
    id,
    data,
    spinner = true,
    log = true,
    alert = true,
    successMessage = 'Data updated successfully!',
    errorMessage = 'Error',
    hostURL = hostUrl
  ) {
    const config = configuration.getAuthConfig();
    return CRUD.patch(
      baseUrl,
      id,
      data,
      hostURL,
      config,
      spinner,
      log,
      alert,
      successMessage,
      errorMessage
    );
  },
  delete(
    baseUrl,
    id,
    spinner = true,
    log = true,
    alert = true,
    successMessage = 'Data deleted successfully!',
    errorMessage = 'Error'
  ) {
    const config = configuration.getAuthConfig();
    return CRUD.remove(
      baseUrl,
      id,
      hostUrl,
      config,
      spinner,
      log,
      alert,
      successMessage,
      errorMessage
    );
  },

  upload(
    baseUrl,
    data,
    object,
    spinner = true,
    log = true,
    alert = true,
    successMessage = 'Data uploaded successfully!',
    errorMessage = 'Error'
  ) {
    const config = configuration.getUploadConfig(object);
    return httpFunction.upload(
      baseUrl,
      data,
      hostUrl,
      config,
      spinner,
      log,
      alert,
      successMessage,
      errorMessage
    );
  },

  login(
    baseUrl,
    data,
    spinner = true,
    log = true,
    alert = true,
    successMessage = 'Logged in successfully!',
    errorMessage = 'Error'
  ) {
    const config = configuration.getConfig();
    return AUTH.login(
      baseUrl,
      data,
      hostUrl,
      config,
      spinner,
      log,
      alert,
      successMessage,
      errorMessage
    );
  },

  register(
    baseUrl,
    data,
    spinner = true,
    log = true,
    alert = true,
    successMessage = 'Form submitted successfully!',
    errorMessage = 'Error'
  ) {
    const config = configuration.getConfig();
    return AUTH.register(
      baseUrl,
      data,
      hostUrl,
      config,
      spinner,
      log,
      alert,
      successMessage,
      errorMessage
    );
  },

  logout() {
    localStorage.clear();
    this.$router.push('/login');
  }
};
