import types from '../mutation-types';
import featureControlService from 'src/services/featureControl';

const state = {
  features: [],
  featureSubscriptions: [],
};

const getters = {
  features: (state) => state.features,
  featureSubscriptions: (state) => state.featureSubscriptions,
};

const actions = {
  async addNewFeature({ commit, dispatch }, payload) {
    const [err, res] = await featureControlService.createFeature({
      name: payload.featureName,
    });
    if (err) {
      return;
    }
    commit(types.ADD_FEATURE, res);
    dispatch('getAllFeatures');
  },

  async deleteFeature({ commit, dispatch }, payload) {
    const [err, res] = await featureControlService.suppressFeature(
      payload.feature_id
    );
    if (err) {
      return;
    }
    commit(types.DELETE_FEATURE, res);
    dispatch('getAllFeatures');
  },

  async getAllFeatures({ commit }) {
    try {
      const [err, features] = await featureControlService.fetchAllFeatures();
      if (err) {
        return;
      }
      if (features && features.length >= 1) {
        commit(types.GET_FEATURES, features);
      }
    } catch (e) {
      commit(types.GET_FEATURES, []);
    }
  },

  async addNewFeatureSubscription({ commit, dispatch }, payload) {
    const [err, res] = await featureControlService.createFeatureSubscription({
      Feature: payload.feature_id,
      Organization: payload.organization_id,
    });
    if (err) {
      return;
    }
    dispatch('getAllFeatureSubscriptions');
  },

  async deleteFeatureSubscription({ commit, dispatch }, payload) {
    const [err, res] = await featureControlService.deleteSubscriptionFeature(
      payload.subscribed_feature_id
    );
    if (err) {
      return;
    }
    dispatch('getAllFeatureSubscriptions');
  },

  async getAllFeatureSubscriptions({ commit, dispatch }) {
    try {
      const [err, featureSubscriptions] =
        await featureControlService.fetchAllFeatureSubscriptions(false);
      if (err) {
        return;
      }
      if (featureSubscriptions && featureSubscriptions.length >= 1) {
        dispatch('setFeatureSubscription', featureSubscriptions);
      }
    } catch (e) {
      dispatch('setFeatureSubscription', []);
    }
  },

  setFeatureSubscription({ commit }, payload) {
    commit(types.SET_FEATURE_SUBSCRIPTIONS, payload);
  },
};

const mutations = {
  [types.ADD_FEATURE](state, payload) {
    state.features = [...state.features, { ...payload }];
  },
  [types.DELETE_FEATURE](state, payload) {
    state.features = [...state.features, { ...payload }];
  },
  [types.GET_FEATURES](state, payload) {
    state.features = [...payload];
  },
  // [types.ADD_FEATURE_SUBSCRIPTION](state, payload) {
  //   state.featureSubscriptions = [...state.featureSubscriptions, { ...payload }];
  // },
  // [types.DELETE_FEATURE_SUBSCRIPTION](state, payload) {
  //   state.featureSubscriptions = [...state.featureSubscriptions, { ...payload }];
  // },
  [types.SET_FEATURE_SUBSCRIPTIONS](state, payload) {
    state.featureSubscriptions = [...payload];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
