export const getQueryParams = (payload) => {
  const { page, type, sortBy, datetime_start, datetime_end } = payload;
  const params = { page };
  if (type) params['isUploadedVideo'] = type;
  if (datetime_start) params['datetime_start'] = datetime_start;
  if (datetime_end) params['datetime_end'] = datetime_end;
  params['ordering'] = `${sortBy}no_of_comments`;
  if (sortBy === 'none') delete params.ordering;
  return params;
};
