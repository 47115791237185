import { useToast } from 'vue-toastification';

const toast = useToast();

export const handleError = (error) => {
  if (error?.status === 400) {
    console.log(error);
    const [errorMessage] = Object.values(error.data)[0];
    toast.error(errorMessage, {
      timeout: 3000,
    });
  }
  if (error?.status === 429) {
    const [errorMessage] = Object.values(error.data);
    toast.error(errorMessage, {
      timeout: 3000,
    });
  }
};

export const customErrorHandler = (error) => {
  if(!error) return;

  const [errorMessage] = Object.values(error?.data);
  toast.error(errorMessage, {
    timeout: 3000,
  });
};
