import ApiHelper from './index';

async function createEventNotification(payload, spinner = false) {
  return await ApiHelper.post(
    'feature_control/event_notifications',
    payload,
    spinner
  );
}

async function fetchEventNotifications(query = {}, spinner = false) {
  return await ApiHelper.get(
    'feature_control/event_notifications',
    spinner,
    query
  );
}
async function getEventNotification(id, spinner = false) {
  return await ApiHelper.get(
    `feature_control/event_notification/${id}`,
    spinner
  );
}

async function updateEventNotification(id, payload, spinner = false) {
  return await ApiHelper.patch(
    `feature_control/event_notification/${id}`,
    payload,
    spinner
  );
}

async function deleteEventNotification(id, spinner = false) {
  return await ApiHelper.remove(
    `feature_control/event_notification/${id}`,
    spinner
  );
}

async function getRecipients(orgName, eventType, spinner = false) {
  return await ApiHelper.get(
    `feature_control/get_event_notification/${orgName}/${eventType}`,
    spinner
  );
}

async function sendEmail(payload, spinner = false) {
  return await ApiHelper.post(`feature_control/send_email`, payload, spinner);
}

export default {
  createEventNotification,
  fetchEventNotifications,
  getEventNotification,
  updateEventNotification,
  deleteEventNotification,
  getRecipients,
  sendEmail,
};
