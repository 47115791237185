import ApiHelper from './index';

const fetchBarcodes = async (spinner = true) => {
  return await ApiHelper.get('account/barcode/', spinner);
};
const createBarcode = async (spinner = true) => {
  return await ApiHelper.post('account/barcode/', {}, spinner);
};
const createCustomBarcode = async (payload, spinner = true) => {
  return await ApiHelper.put(
    `account/create_custom_barcode/`,
    payload,
    spinner
  );
};
const updateCustomBarcode = async (payload, spinner = true) => {
  return await ApiHelper.patch(
    `account/update_barcode_value/`,
    payload,
    spinner
  );
};
const deleteBarcode = async (barcodeId, spinner = true) => {
  return await ApiHelper.remove(`account/barcode/${barcodeId}`, spinner);
};
const fetchAssociateBarcodes = async (taskId, spinner = true) => {
  return await ApiHelper.get(
    `account/associate_barcode/?task=${taskId}`,
    spinner
  );
};
const createAssociateBarcodes = async (payload, spinner = true) => {
  return await ApiHelper.post('account/associate_barcode/', payload, spinner);
};
const updateAssociateBarcodes = async (id, payload, spinner = true) => {
  return await ApiHelper.patch(
    `account/associate_barcode/${id}/`,
    payload,
    spinner
  );
};

const removeAssociatedBarcode = async (id, spinner = true) => {
  return await ApiHelper.remove(`/account/associate_barcode/${id}/`, spinner);
};

const getUserConfigInfo = async (payload, spinner = true) => {
  return await ApiHelper.get(
    `/account/retrieve-session/?email=${payload.email}&sessionKey=${payload.sessionKey}`,
    spinner
  );
};

export default {
  getUserConfigInfo,
  fetchBarcodes,
  createBarcode,
  deleteBarcode,
  fetchAssociateBarcodes,
  createAssociateBarcodes,
  updateAssociateBarcodes,
  removeAssociatedBarcode,
  createCustomBarcode,
  updateCustomBarcode,
};
